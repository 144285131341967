import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { flexWorkerService as flexWorkerServiceOGP } from 'ogp/services';
import { useInfiniteQuery } from 'react-query';
import { FlexWorkersForShiftFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';

const useGetFlexWorkersForShiftPlanningOGP = (
  shiftId: string,
  pageSize: number,
  filters?: FlexWorkersForShiftFilters
) => {
  const queryKey = QUERY_KEYS_OGP.flexWorkersForShiftThatWorkedForCompany(shiftId, filters);
  const queryFn = async ({ pageParam = 0 }) =>
    await flexWorkerServiceOGP.getFlexWorkersWhoWorkedForCompany({
      shiftId,
      page: pageParam,
      pageSize,
      filters,
    });

  return useInfiniteQuery({
    queryKey,
    queryFn,
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetFlexWorkersForShiftPlanningOGP };
