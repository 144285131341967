import { Box, Divider } from '@mui/material';
import { ShiftClaimListFilters } from './shift-claim-list-filters';
import { ShiftClaimFiltersProvider } from './shift-claim-filters-provider';
import { ShiftClaimList } from './shift-claim-list';

export const ShiftClaims = ({ flexWorkerId }: { flexWorkerId: string }) => {
  return (
    <>
      <Box mb={8} mt={4}>
        <Divider />
      </Box>
      <ShiftClaimFiltersProvider>
        <ShiftClaimListFilters flexWorkerId={flexWorkerId} />
        <Box mt={4} mb={4}>
          <Divider />
        </Box>
        <ShiftClaimList flexWorkerId={flexWorkerId} />
      </ShiftClaimFiltersProvider>
    </>
  );
};
