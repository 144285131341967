import { isString } from 'lodash';
import { useExternalParams } from 'shared/hooks/use-external-params';
import { isShiftClaimState } from 'shared/utils/shift-claim-state';
import { ArchiveFilters } from './use-checkouts-archive-page.types';
import { Sorting } from '../..';

const initialValues = {
  sort: {
    initialValue: 'date',
    type: 'string',
  },
  order: {
    initialValue: 'asc',
    type: 'string',
  },
  fromDate: {
    initialValue: undefined,
    type: 'date',
  },
  toDate: {
    initialValue: undefined,
    type: 'date',
  },
  departmentIds: {
    initialValue: [],
    type: 'array',
  },
  flexWorkerIds: {
    initialValue: [],
    type: 'array',
  },
  states: {
    initialValue: [],
    type: 'array',
  },
} as const;

const useCheckoutsArchivePage = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const filters: ArchiveFilters = {
    departmentIds: params.departmentIds?.filter(isString),
    flexWorkerIds: params.flexWorkerIds?.filter(isString),
    fromDate: params.fromDate,
    toDate: params.toDate,
    states: params.states?.filter(isString).filter(isShiftClaimState),
  };

  const sort = {
    sort: params.sort,
    order: params.order,
  } as Sorting;

  const updateSort = (sortBy: Sorting['sort']) => {
    if (sortBy === params.sort) {
      setParams({
        order: params.order === 'desc' ? 'asc' : 'desc',
      });
    } else {
      setParams({
        sort: sortBy,
        order: 'asc',
      });
    }
  };

  let activeFilters = 0;

  if (params.fromDate || params.toDate) {
    activeFilters += 1;
  }

  if (params.departmentIds && params.departmentIds.length) {
    activeFilters += 1;
  }

  if (params.flexWorkerIds && params.flexWorkerIds.length) {
    activeFilters += 1;
  }

  if (params.states && params.states.length) {
    activeFilters += 1;
  }

  return {
    filters,
    setFilters: setParams,
    filterCount: activeFilters,
    sortState: sort,
    updateSort,
  };
};

export { useCheckoutsArchivePage };
