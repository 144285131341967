import { Certificate, LocationAutoSuggestItem, LocationData } from '@types';
import { WeekDays } from '../../shared/utils/date-utils';
import { GetBranchOfficesResponse } from '../../hq/services/branch-office-service.types';

export type CompanyData = {
  name: string;
  ortId?: string;
  ortStartDate?: string;
  workCooldownMinutes: number;
  receiptCertificateIds: string[];
} & CompanyEmploymentTypes;

export type CompanyListItemBasicData = {
  id: string;
  name: string;
} & CompanyEmploymentTypes;

export type CompanyDetail = CompanyListItemBasicData & {
  description: string;
  branchOffice?: GetBranchOfficesResponse['branchOffices'];
  ortId: string | undefined;
  ortName: string | undefined;
  ortStartDate: string | undefined;
  workCooldownMinutes: number;
  receiptCertificates: Certificate[];
};

type CompanyDetailInOGP = CompanyListItemBasicData & {
  description: string;
  bannerImageUrl: string | null;
  profileImageUrl: string | null;
  archived: boolean;
  locationId: string | null;
  location?: LocationData | LocationAutoSuggestItem;
  socials: Link[];
  weekdayOrts: Ort | undefined;
  bankHolidayOrts: BankHolidayORT;
};

export type CompanyDetailAndJobGroups = {
  company: CompanyDetailInOGP;
  jobGroups: JobGroup[];
};

export type CompanyEmploymentTypes = { offersFreelanceWork: boolean; offersTempWork: boolean };

export type CompanyBasicInfo = {
  id: string;
  name: string;
};

export type CompanyListItem = CompanyListItemBasicData & {
  branchOfficesCount: number;
};

export type CompanyFormData = CompanyDetailInOGP &
  Socials & {
    jobGroupIds: string[];
    jobGroups: JobGroup[];
  };

type JobGroup = {
  name: string;
  id: string;
};

export type Socials = {
  website: string | undefined;
  facebook: string | undefined;
  instagram: string | undefined;
  linkedin: string | undefined;
};
type LinkType = keyof Socials;
export const linkTypes: LinkType[] = ['website', 'facebook', 'instagram', 'linkedin'];

export type Link = {
  url: string | undefined;
  type: LinkType;
};

export type CompanyFormPayload = Omit<CompanyDetailInOGP, 'employmentType'> & {
  socials: Link[];
};

export type OrtListItem = {
  id: string;
  name: string;
};

export type Ort = {
  name: string;
  calendar: OrtCalendar;
};

type OrtCalendar = {
  workDays: OrtWorkDays;
};

type Holiday =
  | 'Nieuwjaarsdag'
  | 'Goede Vrijdag'
  | 'Eerste Paasdag'
  | 'Tweede Paasdag'
  | 'Koningsdag'
  | 'Bevrijdingsdag elk jaar'
  | 'Bevrijdingsdag lustrumjaar'
  | 'Hemelvaartsdag'
  | 'Vrijdag na Hemelvaartsdag'
  | 'Vrijdag voorafgaand Pinksterweekend'
  | 'Eerste Pinksterdag'
  | 'Tweede Pinksterdag'
  | 'Kerstavond'
  | 'Eerste Kerstdag'
  | 'Tweede Kerstdag'
  | 'Oudejaarsavond';

export type BankHolidayORT = Partial<Record<Holiday, OrtPeriod[]>>;

type OrtWorkDays = Record<WeekDays, OrtPeriod[]>;

type OrtPeriod = {
  startTime: string;
  endTime: string;
  percentage: number | null;
};
