export const MINIMUM_DATE = '2021-01-01';
export const MINIMUM_SHIFT_CREATE_DATE = '1000-01-01';

export const getMinDate = ({
  allowDateBeforeToday,
  mode,
}: {
  allowDateBeforeToday: boolean | undefined;
  mode?: 'create' | 'checkout';
}) => {
  if (allowDateBeforeToday && mode === 'create') {
    return {
      minDate: new Date(MINIMUM_SHIFT_CREATE_DATE).toISOString().split('T')[0],
      minTime: null,
    };
  }

  if (allowDateBeforeToday) {
    return { minDate: new Date(MINIMUM_DATE).toISOString().split('T')[0], minTime: null };
  }

  const currentDate = new Date();
  const minDate = currentDate.toISOString().split('T')[0];
  const minTime = currentDate.toTimeString().split(':').slice(0, 2).join(':');

  return {
    minDate,
    minTime,
  };
};
