import { AllJobFormData, CompanyEmploymentTypes, TakeDefined, TempWorkJobFormData } from '@types';
import { Control, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { FormCheckbox, LwFormAutocomplete, LwFormInput, LwFormNumberInput } from 'redesign';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import useGeocode from 'ogp/hooks/queryHooks/locations/useGeocode';
import React, { useEffect, useState } from 'react';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';
import { JobFormState, useGetSharedJobFormElements } from './use-get-shared-job-form-elements';

type TempWorkJobFormElementProps = {
  control: Control<Partial<AllJobFormData>>;
  state: JobFormState;
  disabled?: boolean | undefined;
};

type TempWorkJobFormElements = { [key in keyof AllJobFormData]: React.ReactNode };

const atLeastOneType = (val: any, formData: Partial<AllJobFormData>) => {
  if (!formData.isTempwork && !formData.isFreelance) {
    return 'Vul tenminste 1 arbeidsvorm in';
  }
};

export const useGetJobFormData = (
  state: JobFormState,
  jobData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>,
  employmentType: CompanyEmploymentTypes
): Partial<TempWorkJobFormElements> => {
  const { control, watch, setValue, reset } = useFormContext<Partial<AllJobFormData>>();
  const departmentId = watch('departmentId');
  const { data: department } = useGetDepartmentAsDetail(departmentId);
  const sharedElements = useGetSharedJobFormElements(state, jobData, department);

  const isTempwork = watch('isTempwork');
  const isFreelance = watch('isFreelance');

  useEffect(() => {
    if (department && state === 'create') {
      setValue('location', department.location);
      setValue('VAT', department.VAT);
      setValue('flexPools', []);
      reset((formValues) => ({
        ...formValues,
        flexPool: '',
      }));
    }
  }, [department, setValue, state]);

  const res = {
    flexPoolOnly: sharedElements.flexPoolOnly,
    ortApplicable: sharedElements.ortApplicable,
    departmentId: sharedElements.departmentId,
    name: sharedElements.name,
    isFreelance: (
      <IsFreelanceCheckbox
        control={control}
        state={state}
        disabled={!employmentType.offersFreelanceWork}
      />
    ),
    hourlyRate: <HourlyRate control={control} state={state} disabled={!isFreelance} />,
    mutableUntilHours: (
      <MutableUntilHours control={control} state={state} disabled={!isFreelance} />
    ),
    isTempwork: (
      <IsTempworkCheckbox
        control={control}
        state={state}
        disabled={!employmentType.offersTempWork}
      />
    ),
    cla: <CLA control={control} state={state} disabled={!isTempwork} />,
    breakMinutes: sharedElements.breakMinutes,
    jobTypeId: sharedElements.jobTypeId,
    jobCertificates: sharedElements.jobCertificates,
    flexPools: sharedElements.flexPools,
    VAT: sharedElements.VAT,
    descriptionShort: sharedElements.descriptionShort,
    descriptionLong: sharedElements.descriptionLong,
    jobRequirement: sharedElements.jobRequirement,
    descriptionLawRequirements: sharedElements.descriptionLawRequirements,
    contactName: sharedElements.contactName,
    contactPhoneNumber: sharedElements.contactPhoneNumber,
    costCenter: sharedElements.costCenter,
    location: <Location state={state} />,
    locationUrl: sharedElements.locationUrl,
    imageUrl: sharedElements.imageUrl,
  };

  return res;
};

const IsTempworkCheckbox = ({ control, state, disabled }: TempWorkJobFormElementProps) => {
  const {
    trigger,
    formState: { errors },
  } = useFormContext<Partial<AllJobFormData>>();
  const errorMessage = errors.isTempwork?.message;
  return (
    <Grid item xs={12}>
      <FormCheckbox
        name="isTempwork"
        control={control}
        label="Uitzenden"
        disabled={state !== 'create' || disabled}
        rules={{
          validate: {
            atLeastOneType,
          },
        }}
        onChange={async () => {
          await Promise.all([trigger('isFreelance'), trigger('isTempwork')]);
          await trigger('cla');
        }}
        checkboxError={errorMessage}
      />
    </Grid>
  );
};

const IsFreelanceCheckbox = ({ control, state, disabled }: TempWorkJobFormElementProps) => {
  const {
    trigger,
    formState: { errors },
  } = useFormContext<Partial<AllJobFormData>>();
  const errorMessage = errors.isFreelance?.message;
  return (
    <Grid item xs={12}>
      <FormCheckbox
        name="isFreelance"
        control={control}
        label="Freelance"
        disabled={state !== 'create' || disabled}
        rules={{
          validate: {
            atLeastOneType,
          },
        }}
        onChange={async () => {
          await Promise.all([trigger('isFreelance'), trigger('isTempwork')]);
          await Promise.all([trigger('mutableUntilHours'), trigger('hourlyRate')]);
        }}
        checkboxError={errorMessage}
      />
    </Grid>
  );
};

const HourlyRate = ({ control, state, disabled }: TempWorkJobFormElementProps) => {
  const isDisabled = disabled || state === 'read';
  return (
    <Grid item xs={6}>
      <LwFormNumberInput
        name="hourlyRate"
        label="Uurtarief"
        placeholder="10,50"
        control={control}
        rules={{ required: isDisabled ? undefined : `Voer een uurtarief in` }}
        step={0.01}
        min={0}
        disabled={isDisabled}
      />
    </Grid>
  );
};

const MutableUntilHours = ({ control, state, disabled }: TempWorkJobFormElementProps) => {
  const isDisabled = disabled || state === 'read';
  return (
    <Grid item xs={6}>
      <LwFormNumberInput
        name="mutableUntilHours"
        label="Annuleringstermijn"
        placeholder="In uren"
        control={control}
        rules={{ required: isDisabled ? undefined : 'Voer een annuleringstermijn in' }}
        step={1}
        min={0}
        disabled={isDisabled}
      />
    </Grid>
  );
};

const CLA = ({ control, state, disabled }: TempWorkJobFormElementProps) => {
  const isDisabled = disabled || state === 'read';
  return (
    <Grid item xs={12}>
      <LwFormInput
        name="cla"
        label="Cao / beloningsregeling opdrachtgever"
        control={control}
        rules={{
          required: isDisabled ? undefined : 'Voer een cao/beloningsregeling opdrachtgever in',
        }}
        disabled={isDisabled}
      />
    </Grid>
  );
};

const Location = ({ state }: { state: JobFormState }) => {
  const context = useFormContext<Partial<TempWorkJobFormData>>();
  const watchedAddress = context.watch('location')?.address ?? '';
  const [locationInputValue, setLocationInputValue] = useState(watchedAddress);
  const debouncedInput = useDebounce(locationInputValue, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== watchedAddress);
  const { data, isFetching } = geoQuery;

  return (
    <Grid item xs={12}>
      <LwFormAutocomplete
        name="location"
        label="Locatie"
        control={context.control}
        rules={{ required: 'Voer een locatie in' }}
        disableClearable={true}
        disabled={state === 'read'}
        getOptionKey={(option) => option.hereId ?? ''}
        getOptionLabel={(option) => option.address}
        onChange={(_event, value) => {
          if (value) {
            context.setValue('location', value);
          }
        }}
        isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
        inputValue={locationInputValue}
        onInputChange={(_event, value) => {
          setLocationInputValue(value);
        }}
        options={data ?? []}
        isLoading={isFetching}
      />
    </Grid>
  );
};
