import { departmentService } from 'ogp/services';
import { useMutation } from 'react-query';
import { UpdateDepartmentPayload } from 'ogp/services/department-service.types';

const useUpdateDepartment = () => {
  const mutationFn = async (params: { departmentId: string; payload: UpdateDepartmentPayload }) =>
    await departmentService.updateDepartment(params.departmentId, params.payload);

  return useMutation({ mutationFn });
};

export { useUpdateDepartment };
