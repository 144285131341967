import { Box, Grid, Typography } from '@mui/material';
import { HQGetFlexWorkerDetailsResponse } from '@types';
import { useUpdateFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useUpdateFlexWorker';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { LwButton, LwFormDate } from 'redesign';
import { useAlert } from 'shared/components/alerts';

type Props = {
  data: HQGetFlexWorkerDetailsResponse;
  onClose: () => void;
};

type Form = {
  contractStartDate: string | null;
  contractEndDate: string | null;
};

export const FlexWorkerContractBody = (props: Props) => {
  const { alertSuccess, alertError } = useAlert();
  const updateFlexWorker = useUpdateFlexWorker(props.data.id);
  const form = useForm<Form>({
    defaultValues: {
      contractStartDate: props.data.contractStartDate,
      contractEndDate: props.data.contractEndDate,
    },
  });

  const handleSubmit = useCallback(() => {
    form.handleSubmit((data) => {
      updateFlexWorker.mutate(
        {
          contractStartDate: data.contractStartDate,
          contractEndDate: data.contractEndDate,
        },
        {
          onSuccess: () => {
            props.onClose();
            alertSuccess('Contractdata opgeslagen');
          },
          onError: (e) => {
            alertError(e);
          },
        }
      );
    })();
  }, []);

  return (
    <Box
      padding={6}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Typography mb={2}>Stel de ingangs- en einddatum van het contract in</Typography>
        <Grid container spacing={4} xs={6}>
          <Grid item xs={6}>
            <LwFormDate
              control={form.control}
              name={'contractStartDate'}
              label="Contract ingangsdatum"
              rules={{ required: 'Vul in wanneer het contract ingaat' }}
            />
          </Grid>
          <Grid item xs={6}>
            <LwFormDate
              control={form.control}
              name={'contractEndDate'}
              label="Contract einddatum"
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <LwButton onClick={handleSubmit}>Indienen</LwButton>
        <Box ml={4}>
          <LwButton color="secondary" onClick={props.onClose}>
            Annuleren
          </LwButton>
        </Box>
      </Box>
    </Box>
  );
};
