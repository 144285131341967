import { Box, styled, Typography } from '@mui/material';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { AccreditationListProps } from './accreditations-input.types';
import { IconTrash } from '../../../assets/img';

export const AccreditationsList = ({
  accreditations,
  setAccreditations,
}: AccreditationListProps) => {
  const handleDelete = (accreditation: AccreditationFormRepresentation) => {
    setAccreditations((prev) => prev.filter((acc) => acc.id !== accreditation.id));
  };
  return (
    <Box data-testid="selected-accreditations-list">
      {accreditations.map((accreditation) => (
        <Box
          key={accreditation.id}
          display="flex"
          flexDirection="row"
          gap={2}
          borderBottom="1px solid blue"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={2}
          paddingRight={2}
        >
          <Typography variant="subtitle1">{accreditation.certificateName}</Typography>
          <IconWrapper onClick={() => handleDelete(accreditation)} role="button">
            <IconTrash color="primary" />
          </IconWrapper>
        </Box>
      ))}
    </Box>
  );
};

const IconWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
}));
