import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';

import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pluralizeWithValue } from 'shared/utils/pluralize';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { GetCompaniesResponse } from 'hq/services/company-service.types';

import {
  isFreelanceShift,
  isTempworkShift,
  ShiftDetailResponse,
} from 'shared/services/shift-service-types';
import { useShiftPlanningPage } from 'hq/components/views/planner/shifts/table/hooks/use-shift-planning';
import { ShiftMultiplyCalendar } from '../../components/shift-multiply-calendar/shift-multiply-calendar';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { useHqGetShift } from '../../hooks/hq/use-hq-get-shift';
import { HqShiftForm } from '../hq-shift-create-form';
import { useHqMultiplyShift } from '../../hooks/hq/use-hq-multiply-shift';
import { CompanyInput } from '../../components/forms/shared/inputs/company-input';
import { useHqGeneratePlannerPath } from '../hq-generate-shift-form-path';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';
import { stringifyFilters } from '../../../../utils/stringify-filters';

type ShiftMultiplyProps = {
  shift: ShiftDetailResponse;
  companies: GetCompaniesResponse[];
  shiftId: string;
};

export const HqShiftMultiplyDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Shift ID was supposed to be passed via route param');

  const shiftQuery = useHqGetShift(id);
  const companiesQuery = useGetCompanies();

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return <ShiftMultiply shift={shiftQuery.data} shiftId={id} companies={companiesQuery.data} />;
};

const ShiftMultiply = ({ shift, shiftId, companies }: ShiftMultiplyProps) => {
  const classes = getClasses();
  const navigate = useNavigate();
  const generatePlannerPath = useHqGeneratePlannerPath();
  const { filters: appliedFilters } = useShiftPlanningPage();
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const [value, setValue] = useState<DateTime[]>();

  const dates = useMemo(() => value ?? [], [value]);
  const dateCount = useMemo(() => dates.length, [dates.length]);
  const shiftStartDate = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd');
  const shiftDateLabel = shiftStartDate.toFormat('dd-LL-yyyy');
  const mergedFilters = useMemo(
    () => ({ ...stringifyFilters(appliedFilters), shiftDetailId: shiftId }),
    [appliedFilters, shiftId]
  );

  const mutation = useHqMultiplyShift({
    onSuccess: () => {
      closeDialog();
      alertSuccess(alerts.success.multiplied);
      navigate(generatePlannerPath(stringifyFilters(appliedFilters)));
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const handleSubmit = useCallback(() => {
    openDialog(
      {
        title: 'Shift dupliceren',
        content: `Weet je zeker dat je ${pluralizeWithValue(
          dateCount,
          'nieuwe shift',
          'nieuwe shifts'
        )} wilt toevoegen met deze gegevens?`,
        primaryButton: (props) => <PrimaryButton {...props}>Opslaan</PrimaryButton>,
        secondaryButton: (props) => <SecondaryButton {...props}>Annuleren</SecondaryButton>,
      },
      () => {
        disableDialog();
        mutation.mutate({
          id: shiftId,
          startDates: dates.map((date) => date.toFormat('yyyy-LL-dd')),
        });
      }
    );
  }, [dateCount, dates, disableDialog, mutation, openDialog, shift.job.id, shiftId]);

  const onCancel = useCallback(
    () => navigate(generatePlannerPath(mergedFilters)),
    [navigate, generatePlannerPath, mergedFilters]
  );

  const shiftData: Partial<FreelanceShiftFormData | TempWorkShiftFormData> | undefined =
    useMemo(() => {
      if (isFreelanceShift(shift) && isTempworkShift(shift)) {
        return {
          ...shift,
          hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
          grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
          jobId: shift.job.id,
          companyId: shift.job.companyId,
          departmentId: shift.job.department.id,
        };
      }
      if (isFreelanceShift(shift)) {
        return {
          ...shift,
          hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
          jobId: shift.job.id,
          companyId: shift.job.companyId,
          departmentId: shift.job.department.id,
        };
      }
      if (isTempworkShift(shift)) {
        return {
          ...shift,
          grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
          jobId: shift.job.id,
          companyId: shift.job.companyId,
          departmentId: shift.job.department.id,
        };
      }
    }, [shift]);
  const formMethods = useForm<
    { companyId: string } & (FreelanceShiftFormData | TempWorkShiftFormData)
  >({ defaultValues: shiftData });

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateLabel} in bulk aanmaken`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
            disabledPrimaryButton={dateCount < 1}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CompanyInput
              options={companies.map((i) => ({ label: i.name, value: i.id }))}
              disabled={true}
            ></CompanyInput>
            <HqShiftForm mode="read" />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.calendarExplainer}>
              Selecteer de dagen waarop je de shift nog meer wilt laten plaatsvinden.
            </Typography>
            <ShiftMultiplyCalendar shiftDate={shiftStartDate} onChange={setValue} />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};

const getClasses = makeStyles((theme) => ({
  calendarExplainer: {
    marginBottom: theme.spacing(1),
  },
}));
