import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  createSelectOption,
  FormCheckbox,
  LwFormDate,
  LwFormInput,
  LwFormMultiSelect,
  LwFormSelect,
  LwFormSlider,
  SliderMark,
} from 'redesign';
import { Certificate, CompanyEmploymentTypes, OrtListItem } from '@types';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { HQCertificateWithFileUrl } from 'hq/services/accreditations-service.types';

export type CompanyFormData = {
  name: string;
  ortId: string;
  ortStartDate: string;
  workCooldownMinutes: number;
  receiptCertificates: Certificate[];
} & CompanyEmploymentTypes;

export type CompanyFormState = 'create' | 'update';

type CompanyFormProps = {
  state: CompanyFormState;
  orts: OrtListItem[];
  defaultOrtId?: string;
  certificates: HQCertificateWithFileUrl[];
};

const validateEmploymentTypes = (
  offersFreelanceWork: boolean,
  offersTempWork: boolean,
  isSubmitted: boolean
) => {
  if (isSubmitted && !offersFreelanceWork && !offersTempWork) {
    return 'Er moet minimaal een arbeidsvorm geselecteerd zijn';
  }
};

export const CompanyForm = ({ state, orts, defaultOrtId, certificates }: CompanyFormProps) => {
  const {
    control,
    watch,
    formState: { isSubmitted },
  } = useFormContext<CompanyFormData>();

  const ortOptions = useMemo(() => {
    return orts.map((ort) =>
      createSelectOption(ort, {
        value: 'id',
        label: 'name',
      })
    );
  }, [orts]);

  const sliderMarks: SliderMark[] = useMemo(() => {
    return Array.from({ length: 25 }, (_, i) => ({ value: 60 * i, label: i.toString() }));
  }, []);

  const ortId = watch('ortId');
  const offersTempWork = watch('offersTempWork');
  const offersFreelanceWork = watch('offersFreelanceWork');

  const employmentTypeError = validateEmploymentTypes(
    offersFreelanceWork,
    offersTempWork,
    isSubmitted
  );

  const certificatesOptions = useMemo(() => {
    return certificates.filter((certificate) => certificate.canHaveReceipt);
  }, [certificates]);

  return (
    <Box width={'100%'}>
      <Grid container item spacing={2} xs={6}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam in' }}
            label="Bedrijfsnaam"
            disabled={state !== 'create'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormCheckbox
            name="offersFreelanceWork"
            control={control}
            label="Biedt freelancewerk aan"
            rules={{
              validate: () => employmentTypeError,
            }}
            checkboxError={employmentTypeError}
          />
        </Grid>
        <Grid item xs={6}>
          <FormCheckbox
            name="offersTempWork"
            control={control}
            label="Biedt uitzendwerk aan"
            rules={{
              validate: () => employmentTypeError,
            }}
            checkboxError={employmentTypeError}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect name="ortId" label="ORT" options={ortOptions} control={control} />
        </Grid>
        <Grid item xs={6}>
          <LwFormDate
            name="ortStartDate"
            label="Startdatum"
            control={control}
            minDate={DateTime.now().startOf('day').toISODate()}
            rules={{
              validate: (val) => {
                if (ortId !== null && defaultOrtId == ortId) {
                  return undefined;
                }
                if (val == null && ortId != null) {
                  return 'Voer een startdatum in';
                }
                return undefined;
              },
            }}
            disabled={ortId == null || defaultOrtId == ortId}
          />
        </Grid>
        {certificatesOptions.length ? (
          <Grid item xs={6}>
            <LwFormMultiSelect
              name="receiptCertificates"
              label="Certificaten die worden geaccepteerd als ontvangstbewijzen"
              data-testid="receiptCertificates"
              control={control}
              options={certificatesOptions}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              helperText="Ontvangstbewijzen die een echt certificaat kunnen vervangen"
            ></LwFormMultiSelect>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <LwFormSlider
            name="workCooldownMinutes"
            label="Minimale tijd tussen shifts (in uren)"
            control={control}
            min={0}
            max={1440}
            step={60}
            marks={sliderMarks}
            valueLabelFormat={(value) => `${value / 60} uur`}
            data-testid="work-cooldown-slider"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
