import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { FieldValues, useController } from 'react-hook-form';
import { useCallback } from 'react';
import { FormRadioGroupsProps, RadioOption } from './radio.types';

export const FormRadioGroup = <T extends FieldValues>({
  label,
  name,
  control,
  items,
  onChange,
  disabled,
  required,
}: FormRadioGroupsProps<T>) => {
  const {
    field: { onChange: fieldOnChange, value },
  } = useController({
    name,
    control,
  });

  const convertValueToType = useCallback(
    (value: string): boolean | string | number | undefined => {
      const selectedItem = items.find((item) => String(item.value) === value);
      if (selectedItem) {
        return selectedItem.value;
      }
      return undefined;
    },
    [items]
  );

  const setValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = convertValueToType(e.target.value);
      fieldOnChange(convertedValue);
      if (convertedValue) {
        onChange?.(convertedValue);
      }
    },
    [onChange, convertValueToType]
  );

  return (
    <>
      {label ? <Typography variant="body1">{label}</Typography> : null}
      <RadioGroup aria-labelledby="radio-button-group" name="radio-buttons-group" id={name}>
        {items.map((radio: RadioOption) => {
          return (
            <FormControlLabel
              disabled={disabled}
              id={String(radio.value)}
              key={String(radio.value)}
              value={radio.value}
              label={<Typography variant="subtitle1">{radio.label}</Typography>}
              control={
                <Radio required={required} onChange={setValue} checked={radio.value === value} />
              }
            />
          );
        })}
      </RadioGroup>
    </>
  );
};
