import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { AccreditationFormHQ, AccreditationFormTypes } from './accreditation-form-hq';
import { EditAccreditationToolboxHQ } from './edit-accreditation-toolbox';
import {
  AccreditationType,
  HQCertificateWithFileUrl,
  RawAccreditationData,
} from '../../../../services/accreditations-service.types';
import { invariant } from '../../../../../shared/utils/utils';
import { useGetAccreditationDetail } from '../hooks/use-get-accreditation';
import { RenderUnsettledUI, SmallError, SmallLoader } from '../../../../../shared/components';
import { useGetCertificates } from '../../../../hooks/queryHooks/accreditations/use-get-certificates';
import { WorkerList } from '../../../../services/flex-worker-service.types';
import { Header, Page, Toolbox } from '../../../../../redesign';
import { getLabel } from '../../flexWorkers/flex-worker-select';

export const EditAccreditationHqFormDataProvider = () => {
  const { certificateId, flexWorkerId, accreditationType } = useParams<{
    certificateId: string;
    flexWorkerId: string;
    accreditationType: AccreditationType;
  }>();
  invariant(certificateId, 'Certificaat ID ontbreekt');
  invariant(flexWorkerId, 'Flexwerker ID ontbreekt');
  invariant(accreditationType, 'Accreditatie type ontbreekt');

  const accreditationDetailQuery = useGetAccreditationDetail(
    certificateId,
    flexWorkerId,
    accreditationType
  );

  const certificatesQuery = useGetCertificates();

  if (accreditationDetailQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={accreditationDetailQuery}
      />
    );
  }

  if (certificatesQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={certificatesQuery}
      />
    );
  }

  const { data } = accreditationDetailQuery;

  const flexWorkers: WorkerList[] = [
    {
      workerId: data.flexWorker.id,
      email: data.flexWorker.email,
      firstName: data.flexWorker.firstName,
      infix: data.flexWorker.infix,
      lastName: data.flexWorker.lastName,
    },
  ];

  const worker = flexWorkers.find((fw) => fw.workerId === flexWorkerId);
  invariant(worker, 'Flexwerker niet gevonden');

  return (
    <EditAccreditation
      accreditationData={data}
      certificates={certificatesQuery.data}
      flexWorkers={flexWorkers}
      worker={worker}
    />
  );
};

export const EditAccreditation = ({
  accreditationData,
  certificates,
  flexWorkers,
  worker,
}: {
  accreditationData: RawAccreditationData;
  certificates: HQCertificateWithFileUrl[];
  flexWorkers: WorkerList[];
  worker: WorkerList;
}) => {
  const hasPreviouslyUploadedDocument = !!accreditationData.fileUrl;

  const formMethods = useForm<AccreditationFormTypes>({
    defaultValues: {
      accreditations: hasPreviouslyUploadedDocument
        ? [
            {
              id: accreditationData.certificateId,
            },
          ]
        : [],
      certificate: accreditationData.certificate.id,
      startDate: accreditationData.startDate
        ? DateTime.fromISO(accreditationData.startDate).toFormat('yyyy-MM-dd')
        : undefined,
      expirationDate: accreditationData.expirationDate
        ? DateTime.fromISO(accreditationData.expirationDate).toFormat('yyyy-MM-dd')
        : undefined,
      flexWorkerOption: {
        label: accreditationData.flexWorker.firstName,
        value: accreditationData.flexWorker.id,
      },
      isWithoutDocument: !hasPreviouslyUploadedDocument,
      hasPreviouslyUploadedDocument,
      accreditationState: accreditationData.state,
    },
  });

  const fwOption = formMethods.watch('flexWorkerOption');

  const workerLabel = getLabel(worker);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Certificaat aanpassen" />}
        toolbox={
          <Toolbox>
            <EditAccreditationToolboxHQ />
          </Toolbox>
        }
      >
        <AccreditationFormHQ
          certificates={certificates}
          flexWorkers={flexWorkers}
          formState="update"
          // TODO make own provider and not pass 4 props LW1-16844
          flexWorkerOption={fwOption}
          flexWorkerSearch={workerLabel}
          setFlexWorkerOption={() => {}}
          setFlexWorkerSearch={() => {}}
        />
      </Page>
    </FormProvider>
  );
};
