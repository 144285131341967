import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { projectService } from 'ogp/services';
import { useQuery } from 'react-query';
import { PrebuiltBlock } from 'redesign';
import { ProjectGetParams } from '../../../../../services/project-service.types';

export const useGetProjectsPlanning = (params: ProjectGetParams) => {
  const queryFn = async () => await projectService.getProjects(params);

  return useQuery<Promise<PrebuiltBlock[]>, Error, PrebuiltBlock[]>(
    QUERY_KEYS_OGP.departmentProjects(params.departmentId),
    queryFn
  );
};
