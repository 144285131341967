import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { usePageProvider } from 'ogp/layouts';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import {
  BlockLink,
  createBlock,
  LwBlock,
  LwBlockGridContainer,
  LwButton,
  Page,
  SearchBar,
  Toolbox,
} from 'redesign';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { IconAdd, IconFlexPoolOnlyJob, IconJobs } from 'assets/img';
import { useGetJobsListing } from 'ogp/hooks/queryHooks/jobs/use-get-job-blocks';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'Routes';
import { DepartmentSelect } from '../components';

const Jobs = () => {
  const generatePath = useCompanyRelativePath();
  const [pattern, setPattern] = React.useState('');
  const [{ filters }] = usePageProvider();

  const { data, status, error, refetch } = useGetJobsListing(filters.departmentId);

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }

  const blocks = data.map((block) => createBlock(block));

  const filtered = blocks.filter((job) =>
    job.title.toLocaleLowerCase().includes(pattern.toLocaleLowerCase())
  );

  return (
    <Page
      header={
        <HeaderStatistics
          titleText="Functies"
          subheaderText="Hier zie je alle functies binnen jouw vestiging"
          type="jobs"
        />
      }
      toolbox={
        <Toolbox>
          <LwButton
            to={generatePath(routes.JobsCreate, undefined, filters)}
            startIcon={<IconAdd />}
            color="primary"
            iconColorMode="fill"
          >
            Voeg functie toe
          </LwButton>
          <SearchBar
            onChange={setPattern}
            placeholder="Filter functies op naam"
            initialValue={pattern}
          />
          <Toolbox.PushRight>
            <DepartmentSelect />
          </Toolbox.PushRight>
        </Toolbox>
      }
    >
      {filtered.length === 0 ? (
        <Typography>Er zijn geen functies die aan je filter voldoen.</Typography>
      ) : (
        <LwBlockGridContainer>
          {filtered.map((block) => (
            <Grid item xs={6} md={4} lg={3} key={block.id}>
              <BlockLink to={generatePath(routes.JobsDetail, { id: block.id }, filters)}>
                <LwBlock
                  icon={
                    block.type?.value === 'flex-pool-only-job' ? (
                      <IconFlexPoolOnlyJob {...iconProps} />
                    ) : (
                      <IconJobs {...iconProps} />
                    )
                  }
                  severity={block.type?.severity}
                  id={block.id}
                  title={block.title}
                  subtitle={block.subtitle}
                  dataBlocks={block.dataBlocks}
                />
              </BlockLink>
            </Grid>
          ))}
        </LwBlockGridContainer>
      )}
    </Page>
  );
};

const iconProps: Pick<React.CSSProperties, 'width' | 'height'> = {
  width: 24,
  height: 24,
};

export { Jobs };
