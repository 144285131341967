type FlexPool = { id: string; name: string };

export const hasFlexPoolChanges = (
  defaultFlexPools: FlexPool[],
  currentFlexPools: FlexPool[]
): boolean => {
  if (!currentFlexPools || currentFlexPools.length === 0) {
    return defaultFlexPools.length > 0;
  }

  return defaultFlexPools.some(
    (defaultFlexPool) =>
      !currentFlexPools.some((currentFlexPool) => currentFlexPool.id === defaultFlexPool.id)
  );
};
