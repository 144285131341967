import { Controller, FieldValues } from 'react-hook-form';
import { FormControlLabel, styled, Switch } from '@mui/material';
import { FormSwitchProps } from './form-switch.types';

export const FormSwitch = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  checked,
  onChange,
  className,
  'data-testid': dataTestId,
}: FormSwitchProps<ControlFields>) => {
  return (
    <StyledFormControlLabel
      disabled={disabled}
      className={className}
      htmlFor={name}
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <StyledSwitchControlled
              {...field}
              id={name}
              title={label}
              checked={field.value || checked || false}
              value={field.value ?? false}
              onChange={(e) => {
                field.onChange(e.target.checked);
                if (onChange) {
                  onChange(e.target.checked);
                }
              }}
              disabled={disabled}
              color="primary"
              data-testid={dataTestId}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export const UncontrolledFormSwitch = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  disabled,
  checked,
  onChange,
  className,
  value,
  'data-testid': dataTestId,
}: Omit<FormSwitchProps<ControlFields>, 'control' | 'rules'> & { value: boolean }) => {
  return (
    <StyledFormControlLabel
      disabled={disabled}
      className={className}
      htmlFor={name}
      label={label}
      control={
        <StyledSwitchUncontrolled
          id={name}
          title={label}
          checked={value || checked || false}
          value={value ?? false}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked);
            }
          }}
          disabled={disabled}
          color="primary"
          data-testid={dataTestId}
        />
      }
    />
  );
};

const StyledSwitchUncontrolled = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    transform: 'translateX(-10px) !important',
  },
  '& .Mui-checked': {
    transform: 'translateX(10px) !important',
  },
  '& .MuiIconButton-label': {
    width: '100%',
    height: '100%',
  },
  '& .MuiIconButton-root': {
    marginTop: '2px',
    marginLeft: '2px',
    width: '20px',
    height: '20px',
    justifyContent: 'flex-start',
    boxShadow: '0 0 0 0 transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const StyledSwitchControlled = styled(Switch)(({ theme }) => ({
  marginRight: theme.spacing(4),
  '& .MuiSwitch-switchBase': {
    transform: 'translateX(-11px) !important',
  },
  '& .Mui-checked': {
    transform: 'translateX(11px) !important',
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-root': {
    lineHeight: '20px',
    margin: 0,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(0,0,0,1)',
  },
}));
