import { Dialog } from 'ogp/providers/dialog-provider/components/buttons.types';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { FormRadioGroup } from 'redesign';
import { RadioOption } from 'redesign/forms/radio-group/radio.types';
import React from 'react';
import {
  ShiftClaimCancelationReason,
  ShiftClaimCancelationReasonLabels,
} from './cancelation-dialog.types';
import {
  ShiftClaimant,
  ShiftDetailData,
} from '../../../components/tabs/claimants/claimant-table.types';

export const getHqCancelationDialog = (
  shiftData: ShiftDetailData,
  candidate: ShiftClaimant,
  setCancelationReason: React.Dispatch<React.SetStateAction<ShiftClaimCancelationReason>>
): Dialog => {
  return {
    title: 'Reden van annuleren',
    content: (
      <HQCancelationDialog
        shiftData={shiftData}
        candidate={candidate}
        setCancelationReason={setCancelationReason}
      />
    ),
  };
};

const HQCancelationDialog = ({
  setCancelationReason,
}: {
  shiftData: ShiftDetailData;
  candidate: ShiftClaimant;
  setCancelationReason: React.Dispatch<React.SetStateAction<ShiftClaimCancelationReason>>;
}) => {
  const form = useForm<{ reason: ShiftClaimCancelationReason; applyPayout: boolean }>();
  const items: RadioOption[] = Object.keys(ShiftClaimCancelationReason).map((cancelationReason) => {
    return {
      label:
        ShiftClaimCancelationReasonLabels[
          cancelationReason as keyof typeof ShiftClaimCancelationReason
        ],
      value: cancelationReason,
    };
  });
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <FormRadioGroup
          name="reason"
          control={form.control}
          items={items}
          onChange={(e) => {
            setCancelationReason(e as ShiftClaimCancelationReason);
          }}
        ></FormRadioGroup>
      </Box>
    </Box>
  );
};

export const getOgpCancelationDialog = (isFreelancerWithinCancelationPeriod: boolean): Dialog => {
  if (isFreelancerWithinCancelationPeriod) {
    return {
      title: 'Let op! Je annuleert de flexwerker binnen het annuleringstermijn',
      content:
        'Hierbij heeft de flexwerker recht op 50% van ingeplande uren volgens de Overeenkomst van Opdracht. Bij bevestiging ga je hiermee akkoord.',
    };
  }
  return {
    title: 'Weet je zeker dat je deze inschrijving wilt annuleren?',
  };
};
