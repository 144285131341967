import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { LwButton } from 'redesign';

interface ShiftDetailProps {
  shiftId: string;
}

export const ShiftClaimPlanningDetail = ({ shiftId }: ShiftDetailProps) => {
  const generatePath = useHqRelativePath();

  return (
    <LwButton
      color="secondary"
      onClick={() => {
        const g = generatePath(hqRoutes.PlannerShift, undefined, { shiftDetailId: shiftId });
        window.open(g, '_blank');
      }}
    >
      Planning detail
    </LwButton>
  );
};
