import { IconApproved, IconCancel } from 'assets/img';
import { LwButton } from 'redesign';
import { useNavigate } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { Box } from '@mui/material';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { accreditationsAlerts, useAlert } from 'shared/components/alerts';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { AccreditationFormTypes } from './accreditation-form-hq';
import { useAddAndAcceptAccreditation } from '../hooks/use-add-and-approve-accreditation';
import { AccreditationType } from '../../../../services/accreditations-service.types';

export const AddAccreditationsToolboxHQ = () => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useAddAndAcceptAccreditation();
  const { watch } = useFormContext<AccreditationFormTypes>();

  const {
    flexWorkerOption,
    expirationDate,
    accreditations,
    startDate,
    isWithoutDocument,
    certificate,
  } = watch();

  const flexWorkerId = flexWorkerOption?.value;
  const canSubmitWithoutDocument = isWithoutDocument && !!startDate && !!certificate;
  const canSubmitWithDocument = !!accreditations?.length && !isWithoutDocument;

  const handleAddAndAcceptAccreditation = useCallback(() => {
    if (!flexWorkerId) {
      return;
    }

    let accreditationsToSubmit: AccreditationFormRepresentation[];
    if (canSubmitWithDocument && accreditations) {
      accreditationsToSubmit = accreditations;
    } else if (canSubmitWithoutDocument && certificate && startDate) {
      accreditationsToSubmit = [
        {
          id: certificate,
        },
      ];
    } else {
      throw new Error('Invalid state');
    }

    openDialog(
      {
        title: expirationDate
          ? 'Wil je dit certificaat uploaden?'
          : 'Weet je zeker dat je dit certificaat zonder vervaldatum wilt uploaden?',
        secondaryButton: (props) => (
          <SecondaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </SecondaryButton>
        ),
        primaryButton: (props) => (
          <PrimaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </PrimaryButton>
        ),
      },
      () => {
        disableDialog();

        const mutationData = {
          flexWorkerId,
          accreditations: accreditationsToSubmit,
          startDate: canSubmitWithoutDocument ? startDate : null,
          expirationDate,
          accreditationType: AccreditationType.FULL,
        };
        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            alertSuccess(accreditationsAlerts.success.addedAndAccepted);
            navigate(generatePath(hqRoutes.Accreditations));
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  }, [flexWorkerId, accreditations, expirationDate, mutation]);

  const submitDisabled = !flexWorkerOption || !(canSubmitWithoutDocument || canSubmitWithDocument);

  return (
    <>
      <Box>
        <LwButton
          type="submit"
          iconColorMode="stroke"
          color="primary"
          startIcon={<IconApproved />}
          onClick={handleAddAndAcceptAccreditation}
          disabled={submitDisabled}
        >
          Indienen
        </LwButton>
      </Box>
      <Box>
        <LwButton
          onClick={() => navigate(generatePath(hqRoutes.Accreditations))}
          iconColorMode="fill"
          color="secondary"
          startIcon={<IconCancel />}
        >
          Annuleer
        </LwButton>
      </Box>
    </>
  );
};
