import { branchOfficeAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { TakeDefined } from '@types';
import { usePageProvider } from 'ogp/layouts';
import { UpdateDepartmentPayload } from 'ogp/services/department-service.types';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { Dialog } from 'ogp/providers/dialog-provider/components/buttons.types';
import { IconApproved, IconCancel } from 'assets/img';
import { DepartmentFormDataProvider } from '../../components/department-form/department-form';
import { useGetDepartmentAsForm, useUpdateDepartment } from '../../hooks';
import { hasFlexPoolChanges } from '../../utils/has-flexpool-changes';

const DepartmentEditDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Department id is missing');
  const departmentQuery = useGetDepartmentAsForm(id);

  if (departmentQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  return <DepartmentEdit defaultValues={departmentQuery.data} />;
};

const DepartmentEdit = ({
  defaultValues,
}: {
  defaultValues: TakeDefined<ReturnType<typeof useGetDepartmentAsForm>['data']>;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { id } = useParams<'id'>();
  const [{ filters }] = usePageProvider();
  invariant(id);
  const invalidateQuery = useInvalidateQuery();
  const formMethods = useForm({
    defaultValues,
  });
  const mutation = useUpdateDepartment();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();

  const dialogActions: Dialog = {
    title:
      'De gekozen flexpools zullen worden losgekoppeld van eerder aangemaakte functie templates of shifts.',
    secondaryButton: (props) => (
      <SecondaryButton startIcon={<IconCancel />} {...props}>
        Annuleer
      </SecondaryButton>
    ),
    primaryButton: (props) => (
      <PrimaryButton startIcon={<IconApproved />} {...props}>
        Akkoord
      </PrimaryButton>
    ),
  };

  const handleSubmit = () => {
    formMethods.handleSubmit((payload) => {
      const hasChanges = hasFlexPoolChanges(
        defaultValues.departmentFlexPools || [],
        payload.departmentFlexPools || []
      );

      const formattedPayload: UpdateDepartmentPayload = {
        ...payload,
        departmentFlexPoolIds: payload.departmentFlexPools
          ? payload.departmentFlexPools.map(({ id }) => id)
          : [],
      };

      const performMutation = () => {
        mutation.mutate(
          { departmentId: id, payload: formattedPayload },
          {
            onSuccess: () => {
              closeDialog();
              alertSuccess(alerts.success.updated);
              navigate(generatePath(routes.DepartmentDetail, { id }, filters));
              invalidateQuery(QUERY_KEYS_OGP.branchOffice(id));
            },
            onError: (e) => alertError(e),
            onSettled: () => enableDialog(),
          }
        );
      };

      if (hasChanges) {
        openDialog(dialogActions, () => {
          disableDialog();
          performMutation();
        });
      } else {
        performMutation();
      }
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Afdeling bewerken" />}
        toolbox={
          <Toolbox underline>
            <LwButton iconColorMode="stroke" onClick={handleSubmit} {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              iconColorMode="fill"
              onClick={returnTo(routes.DepartmentDetail, { id }, filters)}
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <DepartmentFormDataProvider state="update" />
      </Page>
    </FormProvider>
  );
};

export { DepartmentEditDataProvider };
