import { IconDocument1 } from 'assets/img';
import { useGetShiftClaimContract } from 'ogp/hooks/queryHooks/shiftclaims/useGetShiftClaimContract';
import { ContextMenuItem } from 'redesign';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { ShiftClaimant } from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';

export const OGPDownloadContractAction = ({ candidate }: { candidate: ShiftClaimant }) => {
  const { alertSuccess, alertError } = useAlert();
  const getContract = useGetShiftClaimContract({
    onSuccess: () => alertSuccess(shiftAlerts.success.downloadOvo),
    onError: (e) => alertError(e),
  });

  return (
    <ContextMenuItem
      onClick={() => getContract.mutate(candidate.claimId)}
      key={`ovo-${candidate.claimId}`}
    >
      <IconDocument1 />
      Overeenkomst
    </ContextMenuItem>
  );
};
