import { shiftClaimService } from 'hq/services';
import { HQShiftClaimCancelPayload } from 'hq/services/shift-claim-service.types';
import { useMutation } from 'react-query';

export const useHqCancelShiftClaim = () => {
  const mutationFn = async ({
    shiftClaimId,
    payload,
  }: {
    shiftClaimId: string;
    payload: HQShiftClaimCancelPayload;
  }) => await shiftClaimService.cancelShiftClaim(shiftClaimId, payload);

  return useMutation(mutationFn);
};
