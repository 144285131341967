import { Box, styled, Typography } from '@mui/material';
import { HQGetFlexWorkerDetailsResponse } from '@types';
import { IconCall, IconCross, IconMail } from 'assets/img';
import { UserAvatar } from 'redesign';
import { formatFullName } from 'shared/utils/formatting';
import { getFlexWorkerLabel } from 'shared/utils/get-flex-worker-label';

type Props = {
  data: HQGetFlexWorkerDetailsResponse;
  onClose: () => void;
};

export const FlexWorkerContractHead = (props: Props) => {
  const avatarLabel = getFlexWorkerLabel(props.data.employmentType);
  const flexWorkerName = formatFullName(props.data);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" padding={4}>
      <Box display="flex" flexDirection="row" alignItems={'center'}>
        <UserAvatar
          firstName={props.data.firstName ?? ''}
          lastName={props.data.lastName ?? ''}
          avatarLabel={avatarLabel}
        />
        <Typography variant="body1" mb={1} ml={4}>
          {flexWorkerName}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row">
        <InfoBox>
          <StyledCallIcon />
          <TextSpacingFromIcon>{props.data.phone}</TextSpacingFromIcon>
        </InfoBox>

        <InfoBox ml={4}>
          <StyledMailIcon />
          <TextSpacingFromIcon>{props.data.email}</TextSpacingFromIcon>
        </InfoBox>

        <StyledIconWrapper onClick={props.onClose} data-testid="close-icon">
          <IconCross width={20} height={20} />
        </StyledIconWrapper>
      </Box>
    </Box>
  );
};

const StyledIconWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  '&:hover': {
    opacity: 0.4,
  },
}));

const StyledCallIcon = styled(IconCall)(() => ({
  width: 24,
  height: 24,
  opacity: 0.4,
}));

const StyledMailIcon = styled(IconMail)(() => ({
  width: 24,
  height: 24,
  opacity: 0.4,
}));

const InfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const TextSpacingFromIcon = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    marginLeft: theme.spacing(2),
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: theme.spacing(5),
  },
}));
