import { Grid, styled } from '@mui/material';
import { useGetFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorkerIdentityCheck';
import { RenderUnsettledUI } from 'shared/components';
import { IDDocumentType, WorkerEmploymentType } from '@types';
import { PlatformSettings } from 'shared/services';
import { FreelanceIdCheck } from './freelance-id-check';
import { TempworkIdCheck } from './tempwork-id-check';

const FlexWorkerIdCheckDataProvider = ({
  flexWorkerId,
  closeModal,
  employmentType,
  platformSettings,
}: {
  flexWorkerId: string;
  closeModal: () => void;
  employmentType: WorkerEmploymentType | null;
  platformSettings: PlatformSettings;
}) => {
  const { data, status, error, refetch } = useGetFlexWorkerIdentityCheck(flexWorkerId);

  if (status !== 'success' || !data) {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }
  const defaultValues = {
    vatNumber: data.vatNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    bsn: data.bsn ?? undefined,
    documentType: data.documentType ?? undefined,
    documentNumber: data.documentNumber ?? undefined,
    issuanceDate: data.issuanceDate ?? undefined,
    expirationDate: data.expirationDate ?? undefined,
    dateOfBirth: data.dateOfBirth,
    selfieImage: data.selfieImage,
    portraitImage: data.portraitImage,
    idDocumentImage: data.idDocumentImage,
  } as const;

  return (
    <FlexWorkerIdCheck
      identityVerificationId={data.id}
      flexWorkerId={flexWorkerId}
      workerIdentityInformation={defaultValues}
      closeModal={closeModal}
      employmentType={employmentType}
      platformSettings={platformSettings}
    />
  );
};

type FlexWorkerIdCheckProps = {
  identityVerificationId: string;
  flexWorkerId: string;
  workerIdentityInformation: {
    firstName: string | undefined;
    lastName: string | undefined;
    bsn: string | undefined;
    vatNumber: string | undefined;
    documentType: IDDocumentType | undefined;
    documentNumber: string | undefined;
    issuanceDate: string | undefined;
    expirationDate: string | undefined;
    dateOfBirth: string;
    selfieImage: string;
    portraitImage: string;
    idDocumentImage?: string;
  };
  closeModal: () => void;
  employmentType: WorkerEmploymentType | null;
  platformSettings: PlatformSettings;
};

export const FlexWorkerIdCheck = (props: FlexWorkerIdCheckProps) => {
  const {
    identityVerificationId,
    flexWorkerId,
    workerIdentityInformation,
    closeModal,
    employmentType,
    platformSettings,
  } = props;

  if (!employmentType) {
    throw new Error('Employment type of the worker is not defined');
  }

  return (
    <>
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <h2 className="subtitle">ID Check</h2>
        </Grid>
        <Grid item xs={6}>
          <StyledImage src={workerIdentityInformation.portraitImage} alt="Portrait" />
        </Grid>
        <Grid item xs={6}>
          <StyledImage src={workerIdentityInformation.selfieImage} alt="Selfie" />
        </Grid>
      </Grid>
      {employmentType === 'FREELANCER' ? (
        <FreelanceIdCheck
          identityVerificationId={identityVerificationId}
          flexWorkerId={flexWorkerId}
          workerIdentityInformation={workerIdentityInformation}
          closeModal={closeModal}
          platformSettings={platformSettings}
        />
      ) : (
        <TempworkIdCheck
          identityVerificationId={identityVerificationId}
          flexWorkerId={flexWorkerId}
          workerIdentityInformation={workerIdentityInformation}
          closeModal={closeModal}
          platformSettings={platformSettings}
        />
      )}
    </>
  );
};

const StyledImage = styled('img')({
  width: '100%',
});

export { FlexWorkerIdCheckDataProvider };
