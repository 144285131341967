import { routes } from 'ogp/Routes';
import { useSearchParams } from 'react-router-dom';
import { usePageProvider } from 'ogp/layouts';
import { LwButton, LWModal, Page, Toolbox } from 'redesign';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import { RenderUnsettledUI } from 'shared/components';
import { useLevelPagination } from 'ogp/hooks/queryHooks/misc/use-level-pagination';
import { PaginationIndicator } from 'ogp/components/common/pagination-indicator';
import { IconAdd } from 'assets/img';
import { Box } from '@mui/material';
import { useGetFlexPools } from './hooks/use-get-flex-pools';
import { FlexPoolsTable } from './components/table';
import type { FlexPoolsTableColumnDefinition } from './components/table';
import { DepartmentSelect } from '../../components';
import { FlexPoolDetail } from '../flex-pool-detail/flex-pool-detail';

const FlexPools = () => {
  const { id, open, close } = useFlexPoolDetail();
  const [{ filters }] = usePageProvider();
  const pagination = useLevelPagination();
  const flexPoolListQuery = useGetFlexPools({
    page: pagination.page,
    size: pagination.size,
    departmentId: filters.departmentId,
  });

  const handleFlexPoolClick = (row: FlexPoolsTableColumnDefinition) => {
    open(row.id);
  };

  if (flexPoolListQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexPoolListQuery} />;
  }

  return (
    <>
      <Page
        header={
          <HeaderStatistics
            titleText="Flexpools"
            subheaderText="Hier zie je alle Flexpools binnen jouw vestiging"
            type="flex-pools"
          />
        }
        toolbox={
          <Toolbox>
            <LwButton to="create" color="primary" startIcon={<IconAdd />} iconColorMode="fill">
              Voeg flexpool toe
            </LwButton>
            <Toolbox.PushRight>
              <DepartmentSelect />
            </Toolbox.PushRight>
          </Toolbox>
        }
      >
        <Box display="flex" width="100%" maxHeight={'100%'} flexDirection={'column'}>
          <FlexPoolsTable data={flexPoolListQuery.data} onRowClick={handleFlexPoolClick} />
          <Box mt="auto">
            <PaginationIndicator
              pageCount={flexPoolListQuery.data.totalCount}
              pagination={pagination}
            />
          </Box>
        </Box>
      </Page>
      <LWModal isOpen={!!id} onClose={close} testId="flex-pool-detail">
        {id ? <FlexPoolDetail id={id} onClose={close} /> : null}
      </LWModal>
    </>
  );
};

const { queryParams } = routes.FlexPools;

const useFlexPoolDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const open = (id: string) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set(queryParams.FLEXPOOL_ID, id);
        return searchParams;
      },
      {
        replace: true,
      }
    );
  };

  const close = () => {
    setSearchParams(
      (searchParams) => {
        searchParams.delete(queryParams.FLEXPOOL_ID);
        return searchParams;
      },
      {
        replace: true,
      }
    );
  };

  return { id: searchParams.get(queryParams.FLEXPOOL_ID), open, close };
};

export { FlexPools };
