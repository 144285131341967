import { RenderUnsettledUI } from 'shared/components';
import { FlexWorkersTable } from 'shared/components/flex-workers-for-work-table/flex-workers-table';
import { FlexWorkersForShiftFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { useGetFlexWorkersForShiftPlanning } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-flex-workers-for-shift-planning';
import { FlexWorkerTableOptions } from '../../../../flex-workers-for-work-table/flex-workers-for-work-table.types';

type ProviderProps = {
  shiftId: string;
  filters?: FlexWorkersForShiftFilters;
  getRowActions: FlexWorkerTableOptions['getRowActions'];
};

export const ShiftAddFlexWorkerTableProviderHQ = ({
  filters,
  shiftId,
  getRowActions,
}: ProviderProps) => {
  const flexWorkerQuery = useGetFlexWorkersForShiftPlanning(shiftId, DEFAULT_PAGE_SIZE, filters);
  const { status } = flexWorkerQuery;

  if (status !== 'success') {
    return <RenderUnsettledUI data={flexWorkerQuery} />;
  }

  return (
    <FlexWorkersTable
      queryResult={flexWorkerQuery}
      entityId={shiftId}
      getRowActions={getRowActions}
    />
  );
};
