import { HQGetFlexWorkerDetailsResponse } from '@types';

export function shouldShowBsnField(
  flexWorkerData: HQGetFlexWorkerDetailsResponse,
  isVatRequired: boolean
): boolean {
  return (
    !!flexWorkerData.bsn ||
    (flexWorkerData.employmentType === 'FREELANCER' &&
      isVatRequired === false &&
      !flexWorkerData.vatNumber)
  );
}
