import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import {
  CompanyRole,
  FreelanceJobDetailsWithId,
  FreelanceJobFormData,
  isFreelanceJob,
  isTempWorkJob,
  TakeDefined,
  TempWorkJobDetailsWithId,
  TempWorkJobFormData,
  vatLabel,
} from '@types';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { RenderUnsettledUI } from 'shared/components';
import { useDialog } from 'ogp/providers/dialog-provider';
import { useArchiveJob } from 'ogp/hooks/queryHooks/jobs/useArchiveJob';
import { jobAlerts, useAlert } from 'shared/components/alerts';
import { invariant } from 'shared/utils/utils';
import { formatTextValue, Header, LwButton, Page, Toolbox } from 'redesign';
import { IconDuplicate, IconEdit, IconTrash } from 'assets/img';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { formatDuration } from 'shared/utils/formatting';
import { Duration } from 'luxon';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';
import { usePageProvider } from 'ogp/layouts';
import { useGetJob } from 'shared/hooks/query-hooks/use-get-job';
import { GetBranchOfficeResponse } from '../../../../../../services/branch-office-service.types';

type Job<JobFormData> = JobFormData & {
  department: Pick<GetBranchOfficeResponse['branchOffice'], 'name'>;
  jobType: string | undefined;
};

const JobDetailDataProvider = () => {
  const { id } = useParams<'id'>();
  const jobQuery = useGetJob(id);
  const departmentQuery = useGetDepartmentAsDetail(jobQuery.data?.departmentId);
  const jobCreateDataQuery = useGetJobCreateData();

  if (jobCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobCreateDataQuery} />;
  }

  if (jobQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobQuery} />;
  }

  if (departmentQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  const data = {
    ...transformJob(jobQuery.data, departmentQuery.data, jobCreateDataQuery.data),
  };

  return <JobDetail data={data} />;
};

const JobDetail = ({
  data,
}: {
  data:
    | Job<TempWorkJobFormData>
    | Job<FreelanceJobFormData>
    | Job<TempWorkJobFormData & FreelanceJobFormData>;
}) => {
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [{ filters }] = usePageProvider();
  const generatePath = useCompanyRelativePath();
  const labelGroups = getLabelGroups(data);
  const { alertSuccess, alertError } = useAlert();
  const archiveJob = useArchiveJob({
    onSuccess: () => {
      alertSuccess(jobAlerts.success.deleted);
      navigate(generatePath(routes.Jobs));
      closeDialog();
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();

  const handleArchiveJob = () => {
    invariant(id, 'Job id is not defined');
    disableDialog();
    archiveJob.mutate(id);
  };

  const jobRequirementsMapped = [
    ...(data.jobRequirements.skills || []),
    ...(data.jobRequirements.language || []),
    ...(data.jobRequirements.apparel || []),
  ];

  const handleDelete = () => {
    openDialog({ title: 'Weet je zeker dat je deze functie wilt verwijderen?' }, handleArchiveJob);
  };

  return (
    <Page
      header={
        <Header
          labelGroups={labelGroups}
          titleText={data.name}
          subheaderText="Functie details"
        ></Header>
      }
      toolbox={
        <Toolbox>
          <LwButton to={generatePath(routes.Jobs, undefined, filters)} color="secondary">
            Terug
          </LwButton>
          <LwButton
            to={generatePath(routes.JobsEdit, { id }, filters)}
            startIcon={<IconEdit />}
            iconColorMode="fill"
            color="secondary"
          >
            Functie aanpassen
          </LwButton>
          <LwButton
            to={generatePath(routes.JobsDuplicate, { id }, filters)}
            startIcon={<IconDuplicate />}
            iconColorMode="fill"
            color="secondary"
          >
            Functie dupliceren
          </LwButton>
          <AuthenticatedElement roles={CompanyRole.ADMIN} policyGroup={PolicyGroups.COMPANY}>
            <LwButton
              onClick={handleDelete}
              startIcon={<IconTrash />}
              iconColorMode="stroke"
              color="secondary"
            >
              Verwijderen
            </LwButton>
          </AuthenticatedElement>
        </Toolbox>
      }
    >
      <Box>
        <Grid container spacing={6}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Enkel binnen de flexpool
            </Typography>
            <Typography variant="body1">{data.flexPoolOnly ? 'Ja' : 'Nee'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              ORT toegepast
            </Typography>
            <Typography variant="body1">{data.ortApplicable ? 'Ja' : 'Nee'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              BTW-tarief
            </Typography>
            <Typography variant="body1">{vatLabel(data.VAT)}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Locatie
            </Typography>
            <Typography variant="body1">{data.location.address}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Functiegroep
            </Typography>
            <Typography variant="body1">{data.jobType}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Contactpersoon
            </Typography>
            <Typography variant="body1">{data.contactName}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Telefoon contactpersoon
            </Typography>
            <Typography variant="body1">{data.contactPhoneNumber}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Locatie URL
            </Typography>
            <Typography variant="body1">
              <a href={data.locationUrl} target="_blank" rel="noreferrer">
                Link naar locatie
              </a>
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Kostenplaats
            </Typography>
            <Typography variant="body1">{data.costCenter}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Korte omschrijving
            </Typography>
            <Typography variant="body1">{data.descriptionShort}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Lange omschrijving
            </Typography>
            <Typography variant="body1">{data.descriptionLong}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Afspraken en vereisten
            </Typography>
            <Typography variant="body1">{data.descriptionLawRequirements}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Vereiste certificaten
            </Typography>
            {data.requiredCertificates.length > 0 ? (
              <CertfificateList certificates={data.requiredCertificates} />
            ) : (
              <Typography variant="body1">Er zijn geen certificaten vereist</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Aanvullende certificaten
            </Typography>
            {data.jobCertificates.length > 0 ? (
              <CertfificateList certificates={data.jobCertificates} />
            ) : (
              <Typography variant="body1">Er zijn geen certificaten vereist</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Werkvereisten
            </Typography>
            {jobRequirementsMapped.length > 0 ? (
              jobRequirementsMapped.map((jobRequirement) => (
                <Typography key={jobRequirement.id} variant="body1">
                  {jobRequirement.name}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">Geen werkvereisten</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Flexpools
            </Typography>
            {data.flexPools.length > 0 ? (
              data.flexPools.map((flexPool) => (
                <Typography key={flexPool.id} variant="body1">
                  {flexPool.name} -{' '}
                  {flexPool.directClaimAllowed
                    ? 'Directe claim toegestaan'
                    : 'Directe claim niet toegestaan'}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">Geen flexpools</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

const CertfificateList = ({ certificates }: { certificates: { id: string; name: string }[] }) => {
  return (
    <Grid item xs={8}>
      {certificates.map((certificate) => (
        <Typography key={certificate.id} variant="body1">
          {certificate.name}
        </Typography>
      ))}
    </Grid>
  );
};

const getLabelGroups = (data: ReturnType<typeof transformJob>) => {
  const result = [
    {
      title: 'Vestiging',
      description: data.department.name,
    },
  ];
  if (data.isFreelance) {
    result.push({
      title: 'Uurtarief',
      description: formatTextValue(data.hourlyRate, 'currency'),
    });
  }

  if (data.isFreelance) {
    result.push({
      title: 'Annuleringstermijn',
      description: formatDuration(
        Duration.fromMillis(data.mutableUntilHours * 60 * 60 * 1000).toObject()
      ),
    });
    result.push({
      title: 'Pauze',
      description: formatDuration(Duration.fromMillis(data.breakMinutes * 60 * 1000).toObject()),
    });
  }

  return result;
};

// TODO this can be probably fixed with function overloads
const transformJob = (
  job:
    | FreelanceJobDetailsWithId
    | TempWorkJobDetailsWithId
    | (FreelanceJobDetailsWithId & TempWorkJobDetailsWithId),
  department: TakeDefined<ReturnType<typeof useGetDepartmentAsDetail>['data']>,
  jobCreateData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>
):
  | Job<TempWorkJobFormData>
  | Job<FreelanceJobFormData>
  | Job<TempWorkJobFormData & FreelanceJobFormData> => {
  let result = {
    department: {
      name: department.name,
    },
    jobType: jobCreateData.jobGroups.find((jg) => jg.jobType.find((jt) => jt.id === job.jobTypeId))
      ?.name,
  };

  if (isFreelanceJob(job)) {
    const { freelanceJob, ...base } = job;
    result = {
      ...result,
      ...base,
      hourlyRate: freelanceJob.hourlyRateCents / 100,
      mutableUntilHours: freelanceJob.mutableUntilMinutes / 60,
      isFreelance: true,
      isTempwork: false,
    } as Job<FreelanceJobFormData>;
  }
  if (isTempWorkJob(job)) {
    const { tempWorkJob, ...base } = job;
    result = {
      ...result,
      ...base,
      cla: tempWorkJob.cla,
      isTempwork: true,
      isFreelance: false,
    } as Job<TempWorkJobFormData>;
  }
  if (isFreelanceJob(job) && isTempWorkJob(job)) {
    const { freelanceJob, tempWorkJob, ...base } = job;
    result = {
      ...result,
      ...base,
      hourlyRate: freelanceJob.hourlyRateCents / 100,
      mutableUntilHours: freelanceJob.mutableUntilMinutes / 60,
      cla: tempWorkJob.cla,
      isFreelance: true,
      isTempwork: true,
    } as Job<TempWorkJobFormData & FreelanceJobFormData>;
  }

  return result as any;
};

export { JobDetailDataProvider };
