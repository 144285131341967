import { useChooseFlexWorkerFlexPoolsDialog } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/use-choose-flex-worker-flex-pool-dialog';
import { useOgpGetFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/ogp/use-ogp-flex-worker-flex-pools';
import { useOgpUpdateFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/ogp/use-ogp-update-flex-worker-flex-pools';
import { useOgpSetNoShow } from 'shared/components/dialogs/no-show-dialog/query-hooks/ogp/use-ogp-set-no-show';
import { useNoShowDialog } from 'shared/components/dialogs/no-show-dialog/use-no-show-dialog';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';
import { useHqAdmin } from 'shared/hooks';
import { OGPCancelShiftClaimAction } from './claimant-actions/cancel-shift-claim-action';
import { OGPCancelShiftClaimWithReasonAction } from './claimant-actions/cancel-shift-claim-with-reason-action';
import { OGPDownloadContractAction } from './claimant-actions/download-contract-action';
import { OGPNoShowAction } from './claimant-actions/no-show-action';
import { isApplicant } from '../../../components/tabs/helpers';
import { OpenFlexPoolsAction } from '../../shared/claimant-actions/open-flex-pools-action';

export const useOgpGetClaimantActions = () => {
  const { open: openChooseFlexWorkerFlexPools, Element: ChooseFlexWorkerFlexPools } =
    useChooseFlexWorkerFlexPoolsDialog(
      useOgpUpdateFlexWorkerFlexPools,
      useOgpGetFlexWorkerFlexPools
    );

  const {
    open: openNoShow,
    Element: NoShowDialog,
    setIsFlexWorker,
  } = useNoShowDialog(useOgpSetNoShow);

  const isHq = useHqAdmin();

  const getClaimantActions = (candidate: ShiftClaimant, shiftData: ShiftDetailData) => {
    const actions: React.ReactNode[] = [];

    if (isApplicant(candidate)) {
      return actions;
    }

    if (candidate.state !== 'canceled') {
      if (isHq) {
        actions.push(
          <OGPCancelShiftClaimWithReasonAction
            candidate={candidate}
            shiftData={shiftData}
            key="cancel-with-reason"
          />
        );
      }
      actions.push(
        <OGPCancelShiftClaimAction candidate={candidate} shiftData={shiftData} key="cancel" />
      );
    }

    actions.push(
      <OGPNoShowAction
        candidate={candidate}
        shiftData={shiftData}
        openNoShow={openNoShow}
        setIsFlexWorker={setIsFlexWorker}
        key="no-show"
      />
    );

    if (!candidate.isFlexWorker) {
      return actions;
    }

    actions.push(
      <OpenFlexPoolsAction
        open={openChooseFlexWorkerFlexPools}
        shiftData={shiftData}
        candidate={candidate}
        key="flex-pools"
      />
    );

    actions.push(<OGPDownloadContractAction candidate={candidate} key="contract" />);

    return actions;
  };

  return { getClaimantActions, NoShowDialog, ChooseFlexWorkerFlexPools };
};
