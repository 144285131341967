import { Grid, Typography } from '@mui/material';
import { BlockLink, createBlock, LwBlock, LwBlockGridContainer } from 'redesign';
import { IconUser } from '../../../../../../../assets/img';
import { invariant } from '../../../../../../../shared/utils/utils';

export const ProjectPlanningList = ({
  blocks,
  setProjectId,
}: {
  blocks: ReturnType<typeof createBlock>[];
  setProjectId: (projectId: string) => void;
}) => {
  if (blocks.length === 0) {
    return <Typography>Er zijn geen projecten die aan je filter voldoen.</Typography>;
  }
  return (
    <LwBlockGridContainer container spacing={4}>
      {blocks.map((block) => (
        <Grid item xs={6} md={4} lg={3} key={block.id}>
          <BlockLink
            onClick={() => {
              invariant(block.id, 'ID must be defined');
              setProjectId(block.id);
            }}
          >
            <LwBlock
              icon={<IconUser width={24} height={24} />}
              severity={block.type?.severity}
              id={block.id}
              title={block.title}
              subtitle={block.subtitle}
              dataBlocks={block.dataBlocks}
            />
          </BlockLink>
        </Grid>
      ))}
    </LwBlockGridContainer>
  );
};
