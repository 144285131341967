import { useGetCertificates } from 'hq/hooks/queryHooks/accreditations/use-get-certificates';
import { useGetWorkerList } from 'hq/hooks/queryHooks/flexWorkers/use-get-flex-worker-list';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AutocompleteOption, Header, Page, Toolbox } from 'redesign';
import { AccreditationFormHQ, AccreditationFormTypes } from './accreditation-form-hq';
import { AddAccreditationsToolboxHQ } from './add-accreditation-toolbox-hq';

export const AddAccreditationFormHqDataProvider = () => {
  const [flexWorkerSearchValue, setFlexWorkerSearchValue] = useState<string>('');
  const [flexWorkerSelectedOption, setFlexWorkerSelectedOption] =
    useState<AutocompleteOption | null>(null);

  const formMethods = useForm<AccreditationFormTypes>({
    defaultValues: {
      accreditations: null,
      certificate: null,
      startDate: null,
      expirationDate: null,
      flexWorkerOption: null,
      isWithoutDocument: false,
      hasPreviouslyUploadedDocument: false,
    },
  });

  useEffect(() => {
    formMethods.setValue('flexWorkerOption', flexWorkerSelectedOption);
  }, [flexWorkerSelectedOption]);
  const certificatesQuery = useGetCertificates();
  const debouncedFlexWorkerSearchValue = useDebounce(flexWorkerSearchValue, 300);
  const flexWorkersQuery = useGetWorkerList(debouncedFlexWorkerSearchValue);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Certificaat toevoegen" />}
        toolbox={
          <Toolbox>
            <AddAccreditationsToolboxHQ />
          </Toolbox>
        }
      >
        <AccreditationFormHQ
          certificates={certificatesQuery.data || []}
          flexWorkers={flexWorkersQuery.data || []}
          formState="create"
          // TODO make own provider and not pass 4 props LW1-16844
          flexWorkerOption={flexWorkerSelectedOption}
          flexWorkerSearch={flexWorkerSearchValue}
          setFlexWorkerOption={setFlexWorkerSelectedOption}
          setFlexWorkerSearch={setFlexWorkerSearchValue}
        />
      </Page>
    </FormProvider>
  );
};
