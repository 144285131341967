import { flexPoolAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { routes } from 'ogp/Routes';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { invariant } from 'shared/utils/utils';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { useNavigate } from 'react-router-dom';
import { useGetFlexPool, useUpdateFlexPool } from './hooks';
import { FlexPoolForm } from '../shared/components';
import {
  FlexpoolManagementData,
  FlexPoolManagementFormData,
  UpdateFlexPoolPayload,
} from '../../../../../services/flex-pool-service.types';
import { useGetFlexpoolManagementData } from '../flex-pool-create/hooks/use-flex-pool-management-data';
const { queryParams } = routes.FlexPoolEdit;

const FlexPoolEditDataProvider = () => {
  const queryString = useQueryParams();
  const flexPoolId = queryString.get(queryParams.FLEXPOOL_ID);
  const userInfo = useGetOGPUserInfo();
  const companyId = userInfo.data?.userInfo.company.slug;
  invariant(flexPoolId, 'Flexpool ID should be defined');
  invariant(companyId, 'Company id is missing');

  const managementQuery = useGetFlexpoolManagementData(companyId);
  const poolQuery = useGetFlexPool(flexPoolId);

  if (managementQuery.status !== 'success') {
    return <RenderUnsettledUI data={managementQuery} />;
  }

  if (poolQuery.status !== 'success') {
    return <RenderUnsettledUI data={poolQuery} />;
  }

  const branchOffices: FlexPoolManagementFormData['branchOffices'] = {};
  const departments: FlexPoolManagementFormData['departments'] = {};

  poolQuery.data.departments.forEach((department) => {
    departments[department.id] = true;
  });

  // if all departments in a branch office are selected, the branch office is selected
  managementQuery.data.forEach((branchOffice) => {
    const departmentsInBranch = branchOffice.department || [];
    const selectedDepartments = departmentsInBranch.filter(
      (department) => departments[department.id]
    );
    branchOffices[branchOffice.id] = selectedDepartments.length === departmentsInBranch.length;
  });

  const defaultValues: FlexPoolManagementFormData = {
    branchOffices,
    departments,
    description: poolQuery.data.description,
    name: poolQuery.data.name,
    jobs: poolQuery.data.jobs,
  };

  return <FlexPoolEdit defaultValues={defaultValues} managementData={managementQuery.data} />;
};

const FlexPoolEdit = ({
  defaultValues,
  managementData,
}: {
  defaultValues: FlexPoolManagementFormData;
  managementData: FlexpoolManagementData[];
}) => {
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const queryString = useQueryParams();
  const flexPoolId = queryString.get(queryParams.FLEXPOOL_ID);
  invariant(flexPoolId, 'Flexpool ID should be defined');
  const formMethods = useForm<FlexPoolManagementFormData>({
    defaultValues,
  });
  const mutation = useUpdateFlexPool(flexPoolId, {
    onSuccess: () => {
      alertSuccess(alerts.success.updated);
      navigate(generatePath(routes.FlexPools));
    },
    onError: (e) => alertError(e),
  });
  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutation.status);

  // TODO why is there no flexpool detail route?
  const handleCancel = () => {
    navigate(`${generatePath(routes.FlexPools)}?${[queryParams.FLEXPOOL_ID]}=${flexPoolId}`);
  };

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      const payload: UpdateFlexPoolPayload = {
        id: flexPoolId,
        name: data.name,
        description: data.description,
        departmentIds: Object.entries(data.departments).reduce<string[]>(
          (acc, [key, value]) => (value ? [...acc, key] : acc),
          []
        ),
        jobIds: data.jobs?.map((i) => i.id) ?? [],
      };
      mutation.mutate(payload);
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Flexpool aanpassen" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton onClick={handleCancel} iconColorMode="fill" {...cancelButtonProps}>
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <FlexPoolForm state="update" managementData={managementData} />
      </Page>
    </FormProvider>
  );
};

export { FlexPoolEditDataProvider };
