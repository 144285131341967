import { IconFlexPools } from 'assets/img';
import { ContextMenuItem } from 'redesign';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';
import { formatFullName } from 'shared/utils/formatting';

export const OpenFlexPoolsAction = ({
  open,
  shiftData,
  candidate,
}: {
  open: (shiftId: string, flexworkerId: string, flexWorkerFullName: string) => void;
  shiftData: ShiftDetailData;
  candidate: ShiftClaimant;
}) => {
  return (
    <ContextMenuItem
      onClick={() => open(shiftData.id, candidate.workerId, formatFullName(candidate))}
      key={`flexpools-${candidate.claimId}`}
    >
      <IconFlexPools />
      Flexpool beheer
    </ContextMenuItem>
  );
};
