import {
  ApproveIdCheck,
  FlexWorkerDataEdit,
  FlexWorkerFilterCounts,
  FlexWorkerFlexPools,
  FlexWorkerIdCheckDetail,
  FlexWorkerListItem,
  FlexWorkerProfileData,
  FlexWorkerProfileHQ,
  FlexWorkerSchedule,
  FlexWorkersFilters,
  HQGetFlexWorkerDetailsResponse,
  PaginatedCollection,
  ParamsWithPagination,
} from '@types';
import { GetAllFlexWorkersResponse } from 'ogp/services/flex-worker-service.types';
import { FlexWorkerFlexPoolsData } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/hq/use-hq-update-flex-worker-flex-pools';
import { GetFlexWorkersForShiftPlanningFilterProps } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-flex-workers-for-shift-planning.types';
import {
  GetCompleteFlexWorkersResponse,
  GetIncompleteFlexWorkersResponse,
  WorkerList,
} from './flex-worker-service.types';
import { HqApiService } from './hqApiService';
import { FlexWorkersForProjectFilters } from '../../shared/components/flex-workers-for-work-table/flex-workers-for-work.types';

export class FlexWorkerService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Update an existing flexWorker
   */
  public updateFlexWorker = async (flexWorkerId: string, data: FlexWorkerDataEdit) => {
    await this.hqApiService.update({
      path: `flex-workers/${flexWorkerId}`,
      body: data,
    });
  };

  /**
   * Get a list of all flexWorker profiles.
   */
  public getFlexWorkers = async (params: ParamsWithPagination<FlexWorkersFilters>) =>
    await this.hqApiService.get<PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts>({
      path: 'flex-workers',
      params: { ...params },
    });

  /**
   * Get a list of complete flexWorkers profiles.
   */
  public getCompleteFlexWorkers = async () => {
    const { flexWorkers } = await this.hqApiService.get<GetCompleteFlexWorkersResponse>({
      path: 'flex-workers',
    });

    return flexWorkers;
  };

  /**
   * Get a list of flexWorkers with incomplete profiles.
   */
  public getIncompleteFlexWorkers = async () => {
    const { flexWorkers } = await this.hqApiService.get<GetIncompleteFlexWorkersResponse>({
      path: 'flex-workers/incomplete',
    });

    return flexWorkers;
  };

  /**
   * Get details of a single flexWorker
   */
  public getFlexWorker = async (flexWorkerId: string) => {
    const flexWorker = await this.hqApiService.get<HQGetFlexWorkerDetailsResponse>({
      path: `flex-workers/${flexWorkerId}`,
    });

    return flexWorker;
  };

  public getFlexWorkerSchedule = async (flexWorkerId: string) => {
    return await this.hqApiService.get<FlexWorkerSchedule>({
      path: `flex-workers/schedule/${flexWorkerId}`,
    });
  };

  public getFlexWorkerIdCheck = async (flexWorkerId: string) => {
    const { identityVerification } = await this.hqApiService.get<{
      identityVerification: FlexWorkerIdCheckDetail;
    }>({
      path: `identity-verification/${flexWorkerId}`,
    });
    return identityVerification;
  };

  public approveFlexWorkerIdCheck = async (
    identityVerificationId: string,
    approveIdCheck: ApproveIdCheck
  ) => {
    await this.hqApiService.update({
      path: `identity-verification/${identityVerificationId}/approve`,
      body: { ...approveIdCheck },
    });
  };

  public rejectFlexWorkerIdCheck = async (identityVerificationId: string) => {
    await this.hqApiService.delete({
      path: `identity-verification/${identityVerificationId}/deny`,
    });
  };
  /*
  Get selected sectors & skills for a flexWorker
   */
  public getFlexWorkerProfile = async (flexWorkerId: string) => {
    const response = await this.hqApiService.get<FlexWorkerProfileHQ>({
      path: `flex-workers/profile/${flexWorkerId}`,
    });
    return response;
  };

  /**
   * Get the FlexWorker Profile cover for a FlexWorker
   */
  public getFlexWorkerProfileCover = async (wokerId: string) => {
    const response = await this.hqApiService.get<FlexWorkerProfileData>({
      path: `flex-workers/profile-cover/${wokerId}`,
    });
    return response;
  };

  public async activateFlexWorker(flexWorkerId: string) {
    await this.hqApiService.update({
      path: `flex-workers/${flexWorkerId}/activate`,
    });
  }

  public async deactivateFlexWorker(flexWorkerId: string) {
    await this.hqApiService.update({
      path: `flex-workers/${flexWorkerId}/deactivate`,
    });
  }

  public async addHubspotContact(flexWorkerId: string) {
    await this.hqApiService.update({
      path: `flex-workers/${flexWorkerId}/add-hubspot-contact`,
    });
  }

  public async addFlexWorkerToFlexpool(flexWorkerId: string, flexPoolsToAdd: string[]) {
    await this.hqApiService.update({
      path: `flex-workers/${flexWorkerId}/add-to-flex-pools`,
      body: {
        flexPoolsToAdd,
      },
    });
  }

  public async getAllFlexWorkers(props: GetFlexWorkersForShiftPlanningFilterProps) {
    return await this.hqApiService.get<GetAllFlexWorkersResponse>({
      path: `flex-workers/in-relation-to-shift/${props.shiftId}`,
      params: { page: props.page, pageSize: props.pageSize, ...props.filters },
    });
  }

  public getFlexWorkerFlexPools = async (flexWorkerId: string, shiftId: string) => {
    const response = await this.hqApiService.get<FlexWorkerFlexPools[]>({
      path: `flex-workers/${flexWorkerId}/flex-pools-for-shift/${shiftId}`,
    });
    return response;
  };

  public flexWorkerFlexPools = async (queryData: FlexWorkerFlexPoolsData) => {
    const { flexWorkerId, ...otherData } = queryData;
    const response = await this.hqApiService.update({
      path: `flex-workers/${flexWorkerId}/flexPools`,
      body: otherData,
    });
    return response;
  };

  public onboardFlexworkerToPayday = async (flexWorkerId: string): Promise<void> => {
    await this.hqApiService.update({
      path: `flex-workers/onboardToPayday/${flexWorkerId}`,
    });
  };

  public async getAllFlexWorkersForProject(props: {
    projectId: string;
    page: number;
    pageSize: number;
    filters?: FlexWorkersForProjectFilters;
  }) {
    return await this.hqApiService.get<GetAllFlexWorkersResponse>({
      path: `flex-workers/in-relation-to-project/${props.projectId}`,
      params: { page: props.page, pageSize: props.pageSize, ...props.filters },
    });
  }

  /**
   * Get a list of worker list to input
   */
  public getWorkerList = async (search: string): Promise<WorkerList[]> => {
    return await this.hqApiService.get<WorkerList[]>({
      path: `flex-workers/worker-list`,
      params: {
        search,
      },
    });
  };
}
