import { IconPause } from 'assets/img';
import type { Column } from 'react-table';
import { Box, styled, TableCell, Tooltip, Typography } from '@mui/material';
import { getExpenseWithNameAndValue } from 'shared/utils/expenses';
import {
  PartnerCheckedOutCellProp,
  PartnerCheckedOutColumnDefinition,
} from '../partner-checked-out.types';

const getPartnerCheckedOutColumnDefinition: (
  allowExpenses: boolean
) => Column<PartnerCheckedOutColumnDefinition>[] = (allowExpenses) => {
  const worker = {
    Header: () => <Header>Werker</Header>,
    accessor: 'flexWorkerName',
    Cell: WorkerCell,
    width: '100px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const job = {
    Header: () => <Header>Functie</Header>,
    accessor: 'jobTitle',
    Cell: JobCell,
    width: '75px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const company = {
    Header: () => <Header>Bedrijf</Header>,
    accessor: 'companyName',
    Cell: CompanyNameCell,
    width: '100px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const date = {
    Header: () => <Header>Startdatum</Header>,
    accessor: 'shiftStartDate',
    Cell: DateCell,
    width: '60px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const scheduledTime = {
    Header: () => <Header>Shift tijd</Header>,
    accessor: 'scheduledDateTime',
    Cell: ScheduledTimeCell,
    width: '125px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const workedTime = {
    Header: () => <Header>Gewerkte tijd</Header>,
    accessor: 'workedDateTime',
    Cell: ScheduledTimeCell,
    width: '125px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const expenses = {
    Header: () => <Header>Gemaakte kosten</Header>,
    accessor: 'expense',
    Cell: (cell: PartnerCheckedOutCellProp<'expense'>) => (
      <StyledTableCell>
        {cell.value?.length && (allowExpenses || cell.value.some((i) => i.amount !== 0))
          ? cell.value.map((expense) => (
              <EllipsizedTypography variant="body1" key={expense.expenseType}>
                {getExpenseWithNameAndValue(expense)}
              </EllipsizedTypography>
            ))
          : 'Geen'}
      </StyledTableCell>
    ),
    width: '120px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  const state = {
    Header: () => <Header>Status</Header>,
    accessor: 'status',
    Cell: StateCell,
    width: '75px',
  } as Column<PartnerCheckedOutColumnDefinition>;

  return [worker, job, company, date, scheduledTime, workedTime, expenses, state];
};

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <EllipsizedTypography variant="body2" style={{ fontWeight: 'bold' }}>
    {children}
  </EllipsizedTypography>
);

const WorkerCell = (cell: PartnerCheckedOutCellProp<'flexWorkerName'>) => {
  return (
    <EllipsizedTypography variant="body1" noWrap>
      {cell.value}
    </EllipsizedTypography>
  );
};

const CompanyNameCell = (cell: PartnerCheckedOutCellProp<'companyName'>) => {
  return (
    <EllipsizedTypography variant="body1" noWrap>
      {cell.value}
    </EllipsizedTypography>
  );
};

const JobCell = (cell: PartnerCheckedOutCellProp<'jobTitle'>) => {
  return (
    <EllipsizedTypography variant="body1" noWrap>
      {cell.value}
    </EllipsizedTypography>
  );
};

const StateCell = (cell: PartnerCheckedOutCellProp<'status'>) => {
  const checkoutState = {
    checkoutSettled: 'Akkoord',
    checkoutPending: 'Uren ingediend',
    noShow: 'No show',
  };
  return (
    <BoxState>
      <EllipsizedTypography variant="body1" noWrap>
        {checkoutState[cell.value]}
      </EllipsizedTypography>
    </BoxState>
  );
};

const ScheduledTimeCell = (
  cell: PartnerCheckedOutCellProp<'scheduledDateTime' | 'workedDateTime'>
) => {
  return (
    <BoxDateTime>
      <EllipsizedTypography variant="body1" color="secondary">
        {cell.value.fromDateTime} - {cell.value.toDateTime}
        {cell.value.breakMins ? `, ${cell.value.breakMins} mins` : ''}
      </EllipsizedTypography>
      <IconPauseStyled />
    </BoxDateTime>
  );
};

const DateCell = (cell: PartnerCheckedOutCellProp<'shiftStartDate'>) => {
  return (
    <Tooltip title={cell.value}>
      <EllipsizedTypography variant="body1" noWrap>
        {cell.value}
      </EllipsizedTypography>
    </Tooltip>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: 'none',
}));

const IconPauseStyled = styled(IconPause)(() => ({
  flex: 'none',
  width: '14px',
}));

const BoxDateTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));

const BoxState = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const EllipsizedTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export { getPartnerCheckedOutColumnDefinition };
