import { Table, useOnScrollDown } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { UseInfiniteQueryResult } from 'react-query';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useGetPartnerCheckedOuts } from './hooks/use-get-checked-out';
import { getPartnerCheckedOutTableData } from './utils/get-partner-checked-out-table-data';
import { PartnerCheckedOutColumnDefinition } from './partner-checked-out.types';
import { getPartnerCheckedOutColumnDefinition } from './utils/get-partner-checked-out-column-definition';
import { GetPartnerCheckedOutsResponse } from '../../../shared/services/checked-outs-service.types';
import { GetPartnerCheckedOutParams } from '../../../shared/services/checked-outs-service';

type Props = {
  onSelectionChange: (allSelected: boolean, selectedIds: string[]) => void;
  filters: GetPartnerCheckedOutParams;
};

export const PartnerCheckedOutDataProvider = ({ onSelectionChange, filters }: Props) => {
  const checkedOutQuery = useGetPartnerCheckedOuts(filters);

  if (checkedOutQuery.status !== 'success') {
    return <RenderUnsettledUI data={checkedOutQuery} />;
  }

  return (
    <PartnerCheckedOutTable onSelectionChange={onSelectionChange} queryResult={checkedOutQuery} />
  );
};
const PartnerCheckedOutTable = ({
  onSelectionChange,
  queryResult,
}: Omit<Props, 'filters'> & {
  queryResult: UseInfiniteQueryResult<GetPartnerCheckedOutsResponse, unknown>;
}) => {
  const flatData = useMemo(
    () => queryResult.data?.pages?.flatMap((page) => page.items) ?? [],
    [queryResult.data]
  );
  const { allowExpenses } = useFeatureFlagContext();
  const tableData = useMemo(() => getPartnerCheckedOutTableData(flatData), [flatData]);
  const columns = useMemo(
    () => getPartnerCheckedOutColumnDefinition(allowExpenses),
    [allowExpenses]
  );
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: queryResult,
    flatData: flatData,
  });

  const handleSelectionChange = useCallback(
    (selection: PartnerCheckedOutColumnDefinition[]) => {
      if (selection.length === tableData.length) {
        onSelectionChange(true, []);
        return;
      }
      onSelectionChange(
        false,
        selection.map((i) => i.id)
      );
    },
    [onSelectionChange, tableData.length]
  );

  return (
    <Box maxHeight={'100%'}>
      <Table<PartnerCheckedOutColumnDefinition>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        onScroll={fetchIfBottomScrolled}
        data-testid="checked-out-virtual-table"
        onSelectionChange={handleSelectionChange}
        selectAllMode="VISIBLE"
        selectable={true}
      />
    </Box>
  );
};
