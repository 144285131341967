import { Box, BoxProps, Grid, GridProps, styled } from '@mui/material';
import { IconTrash } from 'assets/img';
import { BaseJobFormData } from '@types';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox, LwIconButton } from 'redesign';
import { FormMode } from 'shared/components/shift-forms/components/forms/shared/types';
type DynamicFlexPoolProps = {
  index: number;
  remove: () => void;
  state: FormMode;
};

export const DynamicFlexPoolItem = ({ index, remove, state }: DynamicFlexPoolProps) => {
  const { control, watch } = useFormContext<Partial<BaseJobFormData>>();

  const label = watch(`flexPools.${index}.name`);

  return (
    <ItemWrap>
      <Grid item xs={5}>
        <FlexPoolLabel>{label}</FlexPoolLabel>
      </Grid>
      <Grid item xs={4}>
        <FormCheckbox
          control={control}
          label="Direct claimen"
          name={`flexPools.${index}.directClaimAllowed`}
          disabled={state === 'read'}
        />
      </Grid>
      {state !== 'read' ? (
        <Grid item xs={1}>
          <IconWrap container item>
            <LwIconButton
              iconColorMode="stroke"
              color="secondary"
              onClick={remove}
              data-testid="remove-item-button"
            >
              <IconTrash />
            </LwIconButton>
          </IconWrap>
        </Grid>
      ) : null}
    </ItemWrap>
  );
};

const ItemWrap = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
  flex: 1,
  marginBottom: 12,
}));

const FlexPoolLabel = styled(Box)<BoxProps>(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Urbanist',
  fontSize: 18,
  fontWeight: 700,
  marginTop: 4,
  fontVariationSettings: '"wght" 700',
  lineHeight: 'normal',
  color: theme.palette.lwBlack[100],
}));

const IconWrap = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: 4,
}));
