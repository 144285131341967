import { shiftClaimService } from 'ogp/services';
import { OGPShiftClaimCancelPayload } from 'ogp/services/shift-claim-service.types';
import { useMutation } from 'react-query';

export const useCancelShiftClaim = () => {
  const mutationFn = async ({
    shiftClaimId,
    payload,
  }: {
    shiftClaimId: string;
    payload: OGPShiftClaimCancelPayload;
  }) => await shiftClaimService.cancelShiftClaim(shiftClaimId, payload);

  return useMutation(mutationFn);
};
