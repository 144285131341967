import { Box, Chip, styled, Tooltip, Typography } from '@mui/material';
import { formatFlexworkerCount } from 'shared/utils/formatting';
import { Column } from 'react-table';
import { LevelTableCellProps, TableCell, TableHeader } from 'redesign';
import { IconWarning } from 'assets/img';
import { FlexPoolsTableColumnDefinition } from './flex-pools-table.types';

export const getFlexPoolsColumnDefinition = (): Column<FlexPoolsTableColumnDefinition>[] => {
  const archived: Column<FlexPoolsTableColumnDefinition> = {
    Header: '',
    accessor: 'allJobsArchived',
    Cell: AllJobsArchivedCell,
  };

  const flexpool: Column<FlexPoolsTableColumnDefinition> = {
    accessor: 'flexPoolName',
    Header: () => <TableHeader>Flexpool</TableHeader>,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  };

  const branchOffice: Column<FlexPoolsTableColumnDefinition> = {
    accessor: 'departmentNames',
    Header: () => <TableHeader>Afdelingen</TableHeader>,
    Cell: DepartmentsCell,
    width: 600,
  };

  const flexWorkerCount: Column<FlexPoolsTableColumnDefinition> = {
    accessor: 'flexWorkerCount',
    Header: () => <TableHeader>Flexwerkers</TableHeader>,
    Cell: ({ value }) => <TableCell>{formatFlexworkerCount(value)}</TableCell>,
  };

  return [archived, flexpool, branchOffice, flexWorkerCount];
};

const AllJobsArchivedCell = ({
  value,
}: LevelTableCellProps<
  FlexPoolsTableColumnDefinition,
  FlexPoolsTableColumnDefinition['allJobsArchived']
>) => {
  if (!value) {
    return null;
  }
  return (
    <Tooltip title="Let op! Er is geen functie gekoppeld aan deze flexpool">
      <Box textAlign="center" display="flex" justifyContent="center">
        <Warning width={24} height={24} />
      </Box>
    </Tooltip>
  );
};

const DepartmentsCell = ({
  value,
}: LevelTableCellProps<
  FlexPoolsTableColumnDefinition,
  FlexPoolsTableColumnDefinition['departmentNames']
>) => {
  const depLen = value.length;
  const extraDepartments = value.slice(4).join(', ');

  return (
    <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {value.slice(0, 4).map((department, index) => (
        <Chip key={index} label={department} style={{ marginBottom: 0 }} />
      ))}
      {depLen > 4 && (
        <Tooltip title={extraDepartments}>
          <Typography variant="body2">{`+${depLen - 4} afdelingen`}</Typography>
        </Tooltip>
      )}
    </TableCell>
  );
};

const Warning = styled(IconWarning)({
  '& path': {
    stroke: 'orange',
  },
});
