import { useQuery } from 'react-query';
import { QUERY_KEYS_OGP } from '../../../../../../query-keys';
import { flexPoolService } from '../../../../../../services';

/**
 *
 * @param companyId the id of the company
 * @returns all branch offices with their departments and jobs for a company
 */
const useGetFlexpoolManagementData = (companyId: string) => {
  return useQuery(QUERY_KEYS_OGP.flexpoolManagementData(), () =>
    flexPoolService.getFlexpoolManagementData(companyId)
  );
};

export { useGetFlexpoolManagementData };
