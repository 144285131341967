import { Column } from 'react-table';
import { TableCell, TableHeader } from 'redesign';

export const getDepartmentsColumnDefinition = (): Record<'name', Column<{ name: string }>> => {
  return {
    name: {
      accessor: 'name',
      Header: <TableHeader>Afdeling naam</TableHeader>,
      Cell: ({ value }) => <TableCell>{value}</TableCell>,
    },
  };
};
