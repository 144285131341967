import type { ClaimCanceled, ShiftClaimCancelationActors } from '@types';
import { getWorkApplicationCancelationReason } from 'shared/utils/cancelation-reasons';
import { ShiftClaimant } from './claimants/claimant-table.types';
import { ShiftApplicationCancelled, ShiftClaimCancelled } from './cancelled/cancelled-table.types';

const isApplicant = (
  input: ShiftClaimant | ShiftApplicationCancelled | ShiftClaimCancelled
): input is ShiftApplicationCancelled => 'applicationId' in input;
const isCanceledClaim = (input: any): input is ClaimCanceled => 'cancelationActor' in input;
const isCanceled = (state: string) => state === 'canceled';
const isDenied = (state: string) => state === 'denied';
type CancelationReasonToTextType<CancelationReasonObject extends string> = {
  [Key in CancelationReasonObject]: string;
};
type ShiftClaimCancelationReasonsToTextType =
  CancelationReasonToTextType<ShiftClaimCancelationActors>;

const shiftClaimCancelationActorsToText = {
  unknown: 'Afwijzing onbekend',
  byFW: 'Annulering door flexwerker',
  byOG: 'Annulering door opdrachtgever',
  byHQ: 'Annulering door HQ',
} as const satisfies ShiftClaimCancelationReasonsToTextType;

const getCancelationReason = (input: ShiftApplicationCancelled | ShiftClaimCancelled): string => {
  if (isApplicant(input) && (isCanceled(input.state) || isDenied(input.state))) {
    return getWorkApplicationCancelationReason(input);
  }
  if (!isApplicant(input) && isCanceledClaim(input)) {
    return shiftClaimCancelationActorsToText[input.cancelationActor];
  }
  return 'Onbekend';
};

export { getCancelationReason, isApplicant };
