import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { departmentService as hqDepartmentService } from 'hq/services';
import { departmentService as ogpDepartmentService } from 'ogp/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { departmentResponse2DepartmentDetailModel } from 'ogp/services/mappers/mappers';

const useGetDepartmentAsDetail = (departmentId: string = '') => {
  const { isHQ } = useSiteDetection();
  const queryKey = isHQ
    ? QUERY_KEYS_HQ.department(departmentId)
    : QUERY_KEYS_OGP.department(departmentId);
  const queryFn = isHQ
    ? async () => await hqDepartmentService.getDepartment(departmentId)
    : async () => await ogpDepartmentService.getDepartment(departmentId);

  return useQuery(queryKey, queryFn, {
    enabled: departmentId.length > 0,
    select: departmentResponse2DepartmentDetailModel,
  });
};

export { useGetDepartmentAsDetail };
