import { useNavigate, useParams } from 'react-router-dom';
import { companyAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useUpdateCompany } from 'hq/hooks/queryHooks/companies/use-update-company';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { FormEvent, useCallback } from 'react';
import { Box } from '@mui/material';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { OrtListItem } from '@types';
import { useGetCertificateNames } from 'hq/components/views/accreditations/hooks/use-get-certificates-names';
import { HQCertificateWithFileUrl } from 'hq/services/accreditations-service.types';
import { CompanyForm, CompanyFormData } from './company-form';
import { companyDataPrepareForSubmit } from './utils';
import { useGetCompanyAsDetail } from '../../../../../hooks/queryHooks/companies/use-get-company-detail';
import { CompanyDetailSelectModel } from '../../../../../services/mappers/mappers';
import { useGetOrts } from '../../../../../hooks/queryHooks/companies/use-get-orts';
import { IconApproved, IconCancel } from '../../../../../../assets/img';

export const CompanyEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Company ID not provided');
  const companyQuery = useGetCompanyAsDetail(id);
  const ortsQuery = useGetOrts();
  const certificatesQuery = useGetCertificateNames();

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  if (ortsQuery.status !== 'success') {
    return <RenderUnsettledUI data={ortsQuery} />;
  }

  if (certificatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={certificatesQuery} />;
  }

  return (
    <CompanyEditImpl
      previousData={companyQuery.data}
      orts={ortsQuery.data}
      companyId={id}
      certificates={certificatesQuery.data}
    />
  );
};

const CompanyEditImpl = ({
  previousData,
  orts,
  companyId,
  certificates,
}: {
  previousData: CompanyDetailSelectModel;
  orts: OrtListItem[];
  companyId: string;
  certificates: HQCertificateWithFileUrl[];
}) => {
  const generatePath = useHqRelativePath();
  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      name: previousData.name,
      ortId: previousData.ortId,
      offersTempWork: previousData.offersTempWork,
      offersFreelanceWork: previousData.offersFreelanceWork,
      workCooldownMinutes: previousData.workCooldownMinutes,
      receiptCertificates: previousData.receiptCertificates,
      ortStartDate: previousData.ortStartDate?.split('T')[0],
    },
  });
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();

  const updateCompany = useUpdateCompany(previousData.id);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      formMethods.handleSubmit((data) => {
        const processedData = companyDataPrepareForSubmit(data);
        updateCompany.mutate(processedData, {
          onSuccess: () => {
            alertSuccess(alerts.success.updated);
            navigate(generatePath(routes.CompanyDetail, { id: companyId }));
          },
          onError: (e) => alertError(e),
        });
      })();
    },
    [previousData]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Page
          header={<Header titleText="Bedrijf bewerken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Bedrijf aanpassen
              </LwButton>
              <LwButton
                onClick={() =>
                  navigate(generatePath(routes.CompanyDetail, { id: previousData.id }))
                }
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <Box width="100%">
            <CompanyForm
              state="update"
              orts={orts}
              defaultOrtId={previousData.ortId}
              certificates={certificates}
            />
          </Box>
        </Page>
      </form>
    </FormProvider>
  );
};
