import { useFormContext } from 'react-hook-form';
import {
  FormSwitch,
  LwFormInput,
  LwFormMultiSelect,
  LwFormTextArea,
  StyledErrorText,
  StyledHelperTextWrapper,
  StyledIconWarning,
  StyledLabel,
} from 'redesign';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormLabel,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import {
  FlexpoolManagementData,
  FlexPoolManagementFormData,
} from '../../../../../../services/flex-pool-service.types';
import { invariant } from '../../../../../../../shared/utils/utils';
import { IconCaretDown } from '../../../../../../../assets/img';

interface Props {
  state: 'create' | 'update' | 'read';
  managementData: FlexpoolManagementData[];
}

const FlexPoolForm = ({ state, managementData }: Props) => {
  return (
    <Grid container item spacing={6} xs={12}>
      <Grid item xs={6}>
        <FlexPoolFormImpl state={state} managementData={managementData} />
      </Grid>
      <Grid item xs={6}>
        <Disclaimer>
          <Typography>
            Let op; wijzigingen gemaakt aan een flexpool heeft alleen effect voor nieuw
            gepubliceerde shifts. Bestaande shifts worden niet aangepast.
          </Typography>
        </Disclaimer>
      </Grid>
    </Grid>
  );
};

const FlexPoolFormImpl = ({
  state,
  managementData,
}: Props & {
  managementData: FlexpoolManagementData[];
}) => {
  const {
    control,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<FlexPoolManagementFormData>();
  invariant(managementData, 'Management data is missing');

  const validateDepartments = () => {
    const departments = watch('departments');
    return Object.values(departments).some((isSelected) => isSelected);
  };

  /**
   * When branches or departments are toggled OFF, we need to unselect the jobs
   * that are associated with those departments
   */
  const updateJobsBasedOnSelectedDepartments = () => {
    const departments = watch('departments');
    // Get all the departments that are not selected
    const unselectedDepartmentIds = Object.entries(departments).reduce<string[]>(
      (acc, [key, value]) => {
        if (!value) {
          acc.push(key);
        }
        return acc;
      },
      []
    );

    // Get all the job IDs that are associated with the unselected departments
    const jobIdsForUnselectedDepartments = managementData
      .flatMap((bo) => bo.department || [])
      .filter((dep) => unselectedDepartmentIds.includes(dep.id))
      .flatMap((dep) => dep.job || [])
      .map((job) => job.id);

    const currentlySelectedJobs = getValues('jobs') || [];
    // Filter out the jobs that are associated with the unselected departments
    const updatedJobs = currentlySelectedJobs.filter(
      (job) => !jobIdsForUnselectedDepartments.includes(job.id)
    );

    // Update the form state with the filtered jobs
    setValue('jobs', updatedJobs);
  };

  /**
   * When a branch office is toggled, we need to ensure that all associated departments are
   * toggled ON if the branch office is selected
   */
  const onBranchOfficeToggle = (branchOfficeId: string, isChecked: boolean) => {
    const branchOffice = managementData.find((bo) => bo.id === branchOfficeId);
    if (branchOffice && branchOffice.department) {
      // Update the state of each associated department
      branchOffice.department.forEach((department) => {
        const departmentIdentifier = `departments.${department.id}` as `departments.${number}`;
        setValue(departmentIdentifier, isChecked);
      });
    }
    updateJobsBasedOnSelectedDepartments();
    trigger('departments');
  };

  /**
   * When a department is toggled, we need to ensure that the branch office is toggled ON if all departments
   *  are selected and OFF if any of the departments are unselected
   */
  const onDepartmentToggle = (branchOfficeId: string) => {
    const branchOffice = managementData.find((bo) => bo.id === branchOfficeId);
    if (branchOffice && branchOffice.department) {
      // Update the branch office status based on department values
      const allDepartmentsChecked = branchOffice.department.every((department) => {
        const depIdentifier = `departments.${department.id}` as `departments.${number}`;
        return watch(depIdentifier);
      });
      const branchOfficeIdentifier = `branchOffices.${branchOfficeId}` as `branchOffices.${number}`;
      setValue(branchOfficeIdentifier, allDepartmentsChecked);
    }
    updateJobsBasedOnSelectedDepartments();
    trigger('departments');
  };

  /**
   * Get the jobs that are selectable based on the selected departments
   */
  const getSelectableJobs = (): { id: string; name: string }[] => {
    const selectableJobs: { id: string; name: string }[] = [];

    managementData.forEach((branchOffice) => {
      branchOffice.department?.forEach((department) => {
        const departments = watch('departments') || [];
        // Check if this department is selected in the form data
        if (departments[department.id]) {
          // If selected, check if jobs exist in this department
          department.job?.forEach((job) => {
            // Only add the job if it's not already included in the selectable jobs
            if (!selectableJobs.some((j) => j.id === job.id)) {
              selectableJobs.push({ id: job.id, name: job.name });
            }
          });
        }
      });
    });

    return selectableJobs;
  };

  return (
    <form>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            label="Flexpool naam"
            control={control}
            rules={{ required: 'Voer een flexpoolnaam in' }}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormTextArea
            name="description"
            control={control}
            label="Omschrijving"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledLabel icon={errors.departments ? <WarningICon /> : null}>Afdelingen</StyledLabel>
          <StyledBox
            className={errors.departments ? 'error' : ''}
            data-testid="branch-offices-container"
          >
            {managementData.map((branchOffice) => {
              const branchOfficeIdentifier =
                `branchOffices.${branchOffice.id}` as `branchOffices.${number}`;
              return (
                <Grid item xs={12} key={branchOffice.id}>
                  <StyledAccordion>
                    <StyledBranchOfficeAccordionHeader
                      aria-controls="panel1-content"
                      id="panel1-header"
                      expandIcon={<IconCaretDown />}
                    >
                      <BranchOfficeName data-testid={`branch-office-name-${branchOffice.id}`}>
                        {branchOffice.name}
                      </BranchOfficeName>
                      <FormSwitch
                        name={branchOfficeIdentifier}
                        control={control}
                        onChange={(val) => onBranchOfficeToggle(branchOffice.id, val)}
                        disabled={state === 'read'}
                        data-testid={`branch-office-toggle-${branchOffice.id}`}
                      />
                    </StyledBranchOfficeAccordionHeader>
                    <AccordionDetails>
                      {branchOffice.department?.map((department) => {
                        const departmentIdentifier =
                          `departments.${department.id}` as `departments.${number}`;
                        return (
                          <ToggleWrapper key={department.id}>
                            <DepartmentName
                              htmlFor={departmentIdentifier}
                              data-testid={`department-name-${department.id}`}
                            >
                              {department.name}
                            </DepartmentName>
                            <FormSwitch
                              name={departmentIdentifier}
                              control={control}
                              onChange={() => onDepartmentToggle(branchOffice.id)}
                              disabled={state === 'read'}
                              data-testid={`department-toggle-${department.id}`}
                              rules={{ validate: validateDepartments }}
                            />
                          </ToggleWrapper>
                        );
                      })}
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
              );
            })}
          </StyledBox>
          <StyledHelperTextWrapper className="error-text-wrapper">
            {errors.departments && (
              <StyledErrorText className={errors.departments ? 'error-state' : ''}>
                {'Selecteer minimaal één afdeling'}
              </StyledErrorText>
            )}
          </StyledHelperTextWrapper>
        </Grid>
        <Grid item xs={12}>
          <LwFormMultiSelect
            disabled={state === 'read'}
            name="jobs"
            control={control}
            options={getSelectableJobs()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => {
              const departments = managementData
                ? managementData.flatMap((bo) => bo.department || [])
                : [];
              const department = departments.find((dep) =>
                dep.job?.some((job) => job.id === option.id)
              );
              return department ? `${option.name} (${department.name})` : option.name;
            }}
            label="Functies"
            data-testid="jobs-multiselect"
          />
        </Grid>
      </Grid>
    </form>
  );
};

const Disclaimer = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[100],
  padding: theme.spacing(4),
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
}));

const ToggleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(4),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  marginBottom: theme.spacing(2),

  '&:last-of-type': {
    marginBottom: 0,
  },
}));

const StyledBranchOfficeAccordionHeader = styled(AccordionSummary)(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BranchOfficeName = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  paddingLeft: theme.spacing(3),
}));

const DepartmentName = styled(FormLabel)(({ theme }) => ({
  paddingLeft: theme.spacing(9),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.lwSecondary[40]}`,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(2),
  '&.error': {
    borderColor: theme.palette.lwDanger[100],
    boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
  },
}));

const WarningICon = styled(StyledIconWarning)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 1),
}));

export { FlexPoolForm };
