import { AccreditationsColumnDefinition } from './accreditations-table.types';
import { RawAccreditationData } from '../../../services/accreditations-service.types';

export const getAccreditationsTableData = (data: RawAccreditationData[]) => {
  const result: AccreditationsColumnDefinition[] = data.map((item) => {
    return {
      flexWorkerId: item.flexWorkerId,
      certificateId: item.certificateId,
      type: item.type,
      flexWorkerName: `${item.flexWorker.firstName} ${item.flexWorker.lastName}`,
      email: item.flexWorker.email,
      certificate: item.certificate.name,
      submittedDate: item.createdAt,
      state: item.state,
      actions: {
        flexWorkerId: item.flexWorkerId,
        certificateId: item.certificateId,
      },
    };
  });
  return result;
};
