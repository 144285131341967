import { ContextMenuItem, Table } from 'redesign';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAccreditationsColumnDefinition } from './get-accreditations-column-definition';
import { getAccreditationsTableData } from './get-accreditations-table-data';
import { AccreditationParams, SetAccreditationParams } from './accreditations.types';
import {
  AccreditationsColumnDefinition,
  AccreditationTableDefinition,
} from './accreditations-table.types';
import {
  AccreditationType,
  RawAccreditationData,
} from '../../../services/accreditations-service.types';
import { useHqRelativePath } from '../../../hooks/useHqRelativePath';
import { hqRoutes } from '../../../Routes';

type AccreditationsTableProps = {
  data: RawAccreditationData[];
  params: AccreditationParams;
  setParams: SetAccreditationParams;
};

const AccreditationsTable = ({ data, params, setParams }: AccreditationsTableProps) => {
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const columns = useMemo(() => getAccreditationsColumnDefinition(params.state), [params.state]);
  const tableData = useMemo(() => getAccreditationsTableData(data), [data]);

  const handleSortClick = () => {
    setParams({ order: params.order === 'desc' ? 'asc' : 'desc' });
  };

  const getMenuActionsForAccreditation = (
    flexWorkerId: string,
    certificateId: string,
    accreditationType: AccreditationType
  ) => {
    return [
      <ContextMenuItem
        key="detail"
        onClick={() => {
          setParams({ flexWorkerId, certificateId, accreditationType });
        }}
      >
        Bekijken
      </ContextMenuItem>,
      <ContextMenuItem
        key="edit"
        onClick={() => {
          navigate(
            generatePath(hqRoutes.AccreditationsEdit, {
              flexWorkerId,
              certificateId,
              accreditationType,
            })
          );
        }}
      >
        Aanpassen
      </ContextMenuItem>,
    ];
  };

  return (
    <>
      <Box height="90%">
        <Table<AccreditationsColumnDefinition, AccreditationTableDefinition>
          stickyHeader
          columns={columns}
          data={tableData}
          sortState={params.order}
          getRowActions={getMenuActionsForAccreditation}
          onSortClick={handleSortClick}
        />
      </Box>
    </>
  );
};

export { AccreditationsTable };
