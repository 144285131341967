import { useMemo } from 'react';
import { WorkerList } from 'hq/services/flex-worker-service.types';
import { LwAutocomplete } from 'redesign/autocomplete/autocomplete';
import { AutocompleteOption } from 'redesign';
import { getFullNameWithInfix } from './utils';

type FlexWorkerSelectProps = FlexWorkerSelectStateProps & {
  workers: WorkerList[];
  disabled?: boolean;
};

export type FlexWorkerSelectStateProps = {
  flexWorkerOption: AutocompleteOption | null;
  flexWorkerSearch: string;
  setFlexWorkerSearch: (value: string) => void;
  setFlexWorkerOption: (value: AutocompleteOption | null) => void;
};

export const getLabel = (worker: WorkerList) => {
  return getFullNameWithInfix(worker) + ' (' + worker.email + ')';
};

export const FlexWorkerSelect = (props: FlexWorkerSelectProps) => {
  const { workers } = props;
  const options = useMemo(
    () =>
      workers.map((worker) => ({
        label: getLabel(worker),
        value: worker.workerId,
      })),
    [workers]
  );

  return (
    <LwAutocomplete
      name="flexWorkerOption"
      isLoading={false}
      label="Flexwerker"
      error={false}
      helperText={undefined}
      inputValue={props.flexWorkerSearch}
      value={props.flexWorkerOption ?? undefined}
      rules={{ required: true }}
      options={options}
      noOptionsText={'Typ minstens 3 tekens om te zoeken'}
      isOptionEqualToValue={(option, value) => option?.value === value.value}
      getOptionKey={(option) => option.value}
      getOptionLabel={(option) => option.label}
      onInputChange={(_e, value) => {
        props.setFlexWorkerSearch(value);
      }}
      onChange={(_, option) => {
        props.setFlexWorkerOption(option);
      }}
      disabled={props.disabled}
    />
  );
};
