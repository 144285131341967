import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { branchOfficeService } from 'ogp/services';
import { useQuery } from 'react-query';

const useGetBranchOfficeCreateData = () => {
  const queryFn = async () => await branchOfficeService.getBranchOfficeCreateData();

  return useQuery(QUERY_KEYS_OGP.brachOfficeCreateData(), queryFn);
};

export { useGetBranchOfficeCreateData };
