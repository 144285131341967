import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'shared/components/alerts';
import { FlexWorkerFlexPools } from '@types';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { flexPoolAlerts as alerts } from 'shared/components/alerts/alert-messages';
import { useQueryClient, UseQueryResult } from 'react-query';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { CheckboxOption, FormCheckboxGroup, LwButton } from 'redesign';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { invariant } from 'shared/utils/utils';
import {
  FlexWorkerFlexPoolsData,
  useOgpUpdateFlexWorkerFlexPools,
} from './hooks/ogp/use-ogp-update-flex-worker-flex-pools';
import { useHqUpdateFlexWorkerFlexPools } from './hooks/hq/use-hq-update-flex-worker-flex-pools';
import { useOgpGetFlexWorkerFlexPools } from './hooks/ogp/use-ogp-flex-worker-flex-pools';
import { useHqGetFlexWorkerFlexPools } from './hooks/hq/use-hq-flex-worker-flex-pools';

type MutationFn = typeof useOgpUpdateFlexWorkerFlexPools | typeof useHqUpdateFlexWorkerFlexPools;
type QueryFn = typeof useOgpGetFlexWorkerFlexPools | typeof useHqGetFlexWorkerFlexPools;

type FlexWorkerFlexPoolsFormData = {
  flexworkerFlexPoolsOptions: string[];
};

export function useChooseFlexWorkerFlexPoolsDialog(
  mutation: MutationFn,
  queryFn: QueryFn,
  companyId?: string
) {
  const methods = useForm<FlexWorkerFlexPoolsFormData>();
  const queryClient = useQueryClient();
  const [shiftId, setShiftId] = useState<string>('');
  const [flexWorkerId, setFlexWorkerId] = useState<string>('');
  const [flexWorkerFullName, setFlexWorkerFullName] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const { alertSuccess, alertError } = useAlert();
  const updateMutation = mutation();
  const query = queryFn(flexWorkerId, shiftId);

  useEffect(() => {
    if (!query.data) {
      methods.setValue('flexworkerFlexPoolsOptions', []);
      return;
    }

    const selectedFlexPoolIds = query.data
      .filter((fwfp) => fwfp.isMember)
      .map((fwfp) => fwfp.flexPoolId);
    methods.setValue('flexworkerFlexPoolsOptions', selectedFlexPoolIds);
  }, [query.data]);

  const open = useCallback((shiftId: string, flexworkerId: string, flexWorkerFullName: string) => {
    setIsOpen(true);
    setShiftId(shiftId);
    setFlexWorkerId(flexworkerId);
    setFlexWorkerFullName(flexWorkerFullName);
  }, []);

  const close = useCallback(() => {
    methods.setValue('flexworkerFlexPoolsOptions', []);
    setIsOpen(false);
  }, []);

  const onSubmit = (formData: FlexWorkerFlexPoolsFormData) => {
    invariant(query.data);
    const selectedOptions: Record<string, boolean> = {};
    formData.flexworkerFlexPoolsOptions.forEach((flexWorkerFlexPool) => {
      selectedOptions[flexWorkerFlexPool] = true;
    });

    const requestData: FlexWorkerFlexPoolsData = {
      flexWorkerId,
      flexPoolsToRemove: [],
      flexPoolsToAdd: [],
      companyId,
    };

    query.data.forEach((flexWorkerFlexPools) => {
      if (selectedOptions[flexWorkerFlexPools.flexPoolId] && !flexWorkerFlexPools.isMember) {
        requestData.flexPoolsToAdd.push(flexWorkerFlexPools.flexPoolId);
      } else if (!selectedOptions[flexWorkerFlexPools.flexPoolId] && flexWorkerFlexPools.isMember) {
        requestData.flexPoolsToRemove.push(flexWorkerFlexPools.flexPoolId);
      }
    });

    updateMutation.mutate(requestData, {
      onSuccess: () => {
        close();
        alertSuccess(alerts.success.updated);
        if (shiftId) {
          queryClient.invalidateQueries(QUERY_KEYS_OGP.shift(shiftId));
        }
      },
      onError: (e) => {
        close();
        alertError(e);
      },
    });
  };

  const Element = (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Flexpool beheer {flexWorkerFullName}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid item xs={12}>
              <FlexWorkerFlexPoolsIndicator query={query} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-around' }}>
            <LwButton color="secondary" onClick={close} autoFocus>
              Annuleren
            </LwButton>
            <LwButton color="primary" type="submit">
              'Bevestigen'
            </LwButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );

  return { open, close, Element };
}

const FlexWorkerFlexPoolsIndicator = ({
  query,
}: {
  query: UseQueryResult<FlexWorkerFlexPools[]>;
}) => {
  const { control } = useFormContext<FlexWorkerFlexPoolsFormData>();

  const options: CheckboxOption[] = useMemo(() => {
    if (!query.data) {
      return [];
    }

    return query.data.map(({ flexPoolName, flexPoolId }: FlexWorkerFlexPools) => {
      return {
        label: flexPoolName,
        value: flexPoolId,
      };
    });
  }, [query.data]);

  if (query.status !== 'success') {
    return <RenderUnsettledUI data={query} />;
  }

  if (query.data?.length === 0) {
    return <Typography>{'Er zijn geen flexpools om weer te geven'}</Typography>;
  }

  return <FormCheckboxGroup name="flexworkerFlexPoolsOptions" control={control} items={options} />;
};
