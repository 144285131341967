import { branchOfficeAlerts } from 'shared/components/alerts';
import { VAT } from '@types';
import { RenderUnsettledUI } from 'shared/components';
import { DepartmentFormData } from 'ogp/services/department-service.types';
import { DepartmentCreatePage } from './department-create-page';
import { useGetCompanyDetailAndJobGroups } from '../../../../../../hooks/queryHooks/companies/use-get-company-detail-and-job-groups';

export const DepartmentCreateDataProvider = () => {
  const companyQuery = useGetCompanyDetailAndJobGroups();

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  const defaultValues: Partial<DepartmentFormData> = {
    companyNameForOVO: companyQuery.data?.company.name,
    VAT: VAT.HIGH,
  };

  return (
    <DepartmentCreatePage
      defaultValues={defaultValues}
      successMessage={branchOfficeAlerts.success.created}
      pageTitle="Nieuwe afdeling aanmaken"
    />
  );
};
