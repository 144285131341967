import { useFormContext } from 'react-hook-form';
import { LwFormDate, LwFormNumberInput, LwFormSelect } from 'redesign';
import { Grid } from '@mui/material';
import { InputSelectOption } from '@types';
import { GrossHourlyRateFormCreateData } from '../create-company-gross-hourly-rate.types';

type Props = {
  areJobTypesLoaded: boolean;
  jobTypeOptions: InputSelectOption[];
};

export const CreateCompanyGrossHourlyRateForm = (props: Props) => {
  const context = useFormContext<GrossHourlyRateFormCreateData>();

  return (
    <Grid container item spacing={2} xs={6}>
      <Grid item xs={6}>
        <LwFormSelect
          name="jobTypeId"
          label="Functienaam"
          options={props.jobTypeOptions}
          control={context.control}
          disabled={!props.areJobTypesLoaded}
          rules={{ required: 'Voer een functienaam in' }}
        />
      </Grid>
      <Grid item xs={6}>
        <LwFormNumberInput
          name="grossHourlyRate"
          label="Bruto uurloon"
          step={0.01}
          control={context.control}
          rules={{ required: 'Voer een bruto uurloon in' }}
          min={0}
        />
      </Grid>
      <Grid item xs={6}>
        <LwFormDate
          name="startDate"
          label="Startdatum"
          control={context.control}
          rules={{ required: 'Voer een startdatum in' }}
        />
      </Grid>
      <Grid item xs={6}>
        <LwFormDate name="endDate" label="Einddatum" control={context.control} />
      </Grid>
    </Grid>
  );
};
