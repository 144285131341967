import { FirstParamOfFn, FlexWorkersFilters, ParamsWithPagination } from '@types';
import { FlexWorkersForShiftFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { projectPlanningService, shiftPlanningService } from './services';
import { AccreditationParams } from './components/views/accreditations/accreditations.types';
/**
 * Alls React-Query keys are managed here.
 * Make sure to have the correct dependency structure for cache invalidation etc.
 * Read this article for more background info: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const QUERY_KEYS_HQ = {
  /** ALL HQ related data. */
  allHQ: () => ['hq'],

  notifications: () => [...QUERY_KEYS_HQ.allHQ(), 'notifications'],

  /** ALL flexWorker related data. */
  flexWorkers: () => [...QUERY_KEYS_HQ.allHQ(), 'flexWorkers'],
  /** FlexWorker overview. */
  flexWorkersList: (params: ParamsWithPagination<FlexWorkersFilters>) => {
    const key = [...QUERY_KEYS_HQ.flexWorkers(), 'list'];
    return params ? [...key, params] : key;
  },
  /** FlexWorker for shift. */
  flexWorkersforShift: (shiftId: string, filters?: FlexWorkersForShiftFilters) => [
    ...QUERY_KEYS_HQ.flexWorkers(),
    'for-shift',
    { shiftId },
    { ...filters },
  ],
  flexWorkerBankAccounts: () => [...QUERY_KEYS_HQ.flexWorkers(), 'bank-accounts'],
  /** FlexWorker overview for complete profiles. */
  completeFlexWorkers: () => [...QUERY_KEYS_HQ.flexWorkers(), 'complete'],
  /** FlexWorker overview for incomplete profiles. */
  incompleteFlexWorkers: () => [...QUERY_KEYS_HQ.flexWorkers(), 'incomplete'],
  /** FlexWorker details. */
  flexWorker: (flexWorkerId: string) => [...QUERY_KEYS_HQ.flexWorkers(), { flexWorkerId }],
  /** FlexWorker profile */
  flexWorkerProfile: (flexWorkerId: string) => [flexWorkerId, 'profile'],
  /** Worker list */
  workerList: (search: string) => [...QUERY_KEYS_HQ.flexWorkers(), 'worker-list', { search }],
  /** FlexWorkers eligible for a flexPool. */
  flexWorkersEligibleForFlexPool: () => [...QUERY_KEYS_HQ.flexWorkers(), 'eligible-for-flexPool'],
  /** FlexWorker schedule */
  schedule: () => [...QUERY_KEYS_HQ.allHQ(), 'schedule'],
  flexWorkerSchedule: (flexWorkerId: string) => [...QUERY_KEYS_HQ.schedule(), { flexWorkerId }],
  /** FlexWorker ID Check */
  identityVerification: () => [...QUERY_KEYS_HQ.allHQ(), 'identity-verification'],
  flexWorkerIdCheck: (flexWorkerId: string) => [
    ...QUERY_KEYS_HQ.identityVerification(),
    { flexWorkerId },
  ],
  // Onboarding to payday from HQ
  onboardToPayday: (flexWorkerId: string) => [flexWorkerId, 'onboardToPayday'],

  /** ALL company related data. */
  companies: () => [...QUERY_KEYS_HQ.allHQ(), 'companies'],
  billingEntities: (companyId: string) => ['billingEntities', { companyId }],
  /** Company overview. */
  companiesList: (filter: string | undefined) => [...QUERY_KEYS_HQ.allHQ(), 'companies', filter],
  /** A single company. */
  company: (companyId: string) => [...QUERY_KEYS_HQ.companies(), { companyId }],
  companyPartners: (companyId: string) => [...QUERY_KEYS_HQ.companies(), { companyId }, 'partners'],
  companyGrossHourlyRate: (companyId: string) => [
    ...QUERY_KEYS_HQ.companies(),
    { companyId },
    'gross-hourly-rate',
  ],
  /** All companies for a flexWorker. */
  companiesForFlexWorker: (flexWorkerId: string) => [
    ...QUERY_KEYS_HQ.companies(),
    'for-flexWorker',
    { flexWorkerId },
  ],
  companyEmploymentTypes: (companyId: string) => [
    ...QUERY_KEYS_HQ.companies(),
    'company-employment-types',
    { companyId },
  ],

  /** ALL branch office related data. */
  branchOffices: () => [...QUERY_KEYS_HQ.allHQ(), 'branch-offices'],
  /** Branch office overview. */
  branchOfficesList: (companyId?: string) => [
    ...QUERY_KEYS_HQ.branchOffices(),
    'list',
    { companyId },
  ],
  /** A single branch office. */
  branchOffice: (branchOfficeId: string) => [...QUERY_KEYS_HQ.branchOffices(), { branchOfficeId }],

  /** ALL department related data. */
  departments: (companyId: string) => [...QUERY_KEYS_HQ.allHQ(), 'departments', { companyId }],
  department: (departmentId: string) => [...QUERY_KEYS_HQ.allHQ(), 'department', { departmentId }],

  /** ALL flexPool related data. */
  flexPools: () => [...QUERY_KEYS_HQ.allHQ(), 'flexPools'],
  /** FlexPool overview. */
  flexPoolList: () => [...QUERY_KEYS_HQ.flexPools(), 'list'],
  /** A single flexPool. */
  flexPool: (flexPoolId: string) => [...QUERY_KEYS_HQ.flexPools(), { flexPoolId }],
  /** All flexPools for a flexWorker. */
  flexPoolsForFlexWorker: (flexWorkerId: string) => [
    ...QUERY_KEYS_HQ.flexPools(),
    'for-flexWorker',
    { flexWorkerId },
  ],

  /** ALL job related data. */
  jobs: () => [...QUERY_KEYS_HQ.allHQ(), 'jobs'],
  job: (jobId: string) => [...QUERY_KEYS_HQ.jobs(), jobId],
  /** Jobs overview for a branch office. */
  jobList: (companyId?: string, branchOfficeId?: string) => [
    ...QUERY_KEYS_HQ.jobs(),
    { companyId, branchOfficeId },
  ],

  requiredCertificatesForJobType: (jobTypeId: string) => [
    ...QUERY_KEYS_HQ.allHQ(),
    'required-certificates-for-job-type',
    { jobTypeId },
  ],

  /** Job details for a work template. */
  jobDetails: (jobId: string) => [...QUERY_KEYS_HQ.jobs(), jobId, 'jobDetails'],

  /** ALL shift related data. */
  shifts: () => [...QUERY_KEYS_HQ.allHQ(), 'shifts'],
  /** A single shift. */
  shift: (shiftId?: string) => [...QUERY_KEYS_HQ.shifts(), { shiftId }],

  /** ALL shift claim related data. */
  shiftClaims: () => [...QUERY_KEYS_HQ.allHQ(), 'shift-claims'],
  /** All shift claims for a flexWorker with filters. */
  shiftClaimsForFlexWorker: (filters: { [key: string]: string }) => [
    ...QUERY_KEYS_HQ.shiftClaims(),
    filters,
  ],
  /** ALL shift partner related data. */
  shiftPartners: () => [...QUERY_KEYS_HQ.allHQ(), 'shift-partners'],
  shiftPartner: (shiftId: string) => [...QUERY_KEYS_HQ.shiftPartners(), shiftId],
  shiftEligiblePartners: (shiftId: string) => [
    ...QUERY_KEYS_HQ.shiftPartners(),
    shiftId,
    'eligible-partners',
  ],
  /** ALL user related data. */
  users: () => [...QUERY_KEYS_HQ.allHQ(), 'users'],
  /** All users overview. */
  userList: () => [...QUERY_KEYS_HQ.users(), 'list'],
  /** User data. */
  user: (userId: string) => [...QUERY_KEYS_HQ.users(), { userId }],
  /** User info (logged in user). */
  userInfo: () => [...QUERY_KEYS_HQ.users(), 'user-info'],

  /** Roles matrix. */
  rolesMatrix: () => [...QUERY_KEYS_HQ.allHQ(), 'roles-matrix'],

  /** Versioning */
  version: () => [...QUERY_KEYS_HQ.allHQ(), 'version'],

  /** Rollouts */
  rollouts: () => [...QUERY_KEYS_HQ.allHQ(), 'rollouts'],

  /** ORTs */
  orts: () => [...QUERY_KEYS_HQ.allHQ(), 'orts'],

  /** Partners */
  partner: (partnerId: string) => [...QUERY_KEYS_HQ.allHQ(), partnerId],
  partners: () => [...QUERY_KEYS_HQ.allHQ(), 'partners'],

  /** Partner Relations*/
  partnerRelations: (partnerId: string) => [
    ...QUERY_KEYS_HQ.partners(),
    'partnerRelations',
    partnerId,
  ],

  /** Partner users */
  partnerUsers: (partnerUserId: string | undefined) => [...QUERY_KEYS_HQ.partners(), partnerUserId],

  partnerUser: (userId: string | undefined) => [...QUERY_KEYS_HQ.partners(), userId],

  /** Shift planning*/
  shiftPlanningShiftsFilters: (
    params: FirstParamOfFn<typeof shiftPlanningService.getShiftPlanningShifts>
  ) => [...QUERY_KEYS_HQ.allHQ(), 'shift-planning-shifts', params],
  plannerShiftsPartners: (shiftIds: string[]) => [
    ...QUERY_KEYS_HQ.allHQ(),
    'planner-shifts-partners',
    ...shiftIds,
  ],
  shiftPlanningShifts: () => [...QUERY_KEYS_HQ.allHQ(), 'shift-planning-shifts'],
  shiftCreateData: (companyId: string) => [...QUERY_KEYS_HQ.allHQ(), companyId],
  businessUnits: () => [...QUERY_KEYS_HQ.allHQ(), 'business-units'],

  jobTypes: () => [...QUERY_KEYS_HQ.allHQ(), 'job-types'],
  jobTypesList: (companyId?: string, branchOfficeId?: string) => [
    ...QUERY_KEYS_HQ.jobTypes(),
    { companyId, branchOfficeId },
  ],

  partnersFilter: () => [...QUERY_KEYS_HQ.allHQ(), 'partners-filter'],

  /** Project planning */
  projectPlanningProjectsFilters: (
    params: FirstParamOfFn<typeof projectPlanningService.getProjectPlanningProjects>
  ) => [...QUERY_KEYS_HQ.allHQ(), 'project-planning-projects', params],
  projects: (departmentId?: string) => ['projects', { departmentId }],
  projectDetail: (projectId: string) => [...QUERY_KEYS_HQ.projects(), { projectId }],
  workApplicationsForProject: (projectId: string) => [
    ...QUERY_KEYS_HQ.allHQ(),
    'project-work-applications',
    { projectId },
  ],

  /** Accreditations */
  accreditations: (filterParams?: AccreditationParams) => {
    const key = [...QUERY_KEYS_HQ.allHQ(), 'accreditations'];
    return filterParams ? [...key, filterParams] : key;
  },

  /** Accreditation certificates */
  accreditationsCertificates: () => [...QUERY_KEYS_HQ.allHQ(), 'accreditationsCertificates'],

  /** Accreditation profile */
  accreditationsProfile: () => [...QUERY_KEYS_HQ.allHQ(), 'accreditationsProfile'],

  /** Accreditation detail */
  accreditationDetail: () => [...QUERY_KEYS_HQ.allHQ(), 'accreditationDetail'],

  /** Feature flags */
  featureFlags: () => [...QUERY_KEYS_HQ.allHQ(), 'featureFlags'],

  /** Platform settings */
  platformSettings: () => [...QUERY_KEYS_HQ.allHQ(), 'platformSettings'],
} as const;
