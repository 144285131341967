import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BaseJobFormData } from '@types';
import { Box, Grid, Typography } from '@mui/material';
import { LwFormSelect } from 'redesign';
import { FormMode } from 'shared/components/shift-forms/components/forms/shared/types';
import { DynamicFlexPoolItem } from './dynamic-flex-pool-item';

export const FlexPoolsSection = ({
  flexPoolOptions,
  state,
}: {
  flexPoolOptions: { id: string; name: string }[];
  state: FormMode;
}) => {
  const { control, setValue } = useFormContext<BaseJobFormData & { flexPool: string }>();
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: '_id',
    name: 'flexPools',
  });

  const selectOptions = useMemo(
    () =>
      flexPoolOptions
        .filter((option) => !fields.some((field) => field.id === option.id))
        .map(({ name, id }) => ({
          label: name,
          value: id,
        })),
    [flexPoolOptions, fields]
  );

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFlexPool = flexPoolOptions.find(({ id }) => id === event.target.value);
    if (selectedFlexPool) {
      append({ ...selectedFlexPool, directClaimAllowed: true });
    }
  };

  return (
    <Grid item xs={12}>
      <LwFormSelect
        name="flexPool"
        label="Flexpools"
        defaultLabel="Selecteer een flexpool"
        onChange={handleSelectChange}
        control={undefined}
        options={selectOptions}
        disabled={state === 'read'}
        data-testid="flex-pool-select"
      />
      {fields.length > 0 && (
        <Box>
          <Typography variant="body1" marginBottom={3}>
            Selecteer flexpools en kies of flexwerkers in die flexpool direct kunnen claimen
          </Typography>
          {fields.map((flexPool, index) => (
            <DynamicFlexPoolItem
              state={state}
              key={flexPool.id}
              index={index}
              remove={() => {
                remove(index);
                setValue('flexPool', '');
              }}
            />
          ))}
        </Box>
      )}
    </Grid>
  );
};
