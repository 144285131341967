import { DateTime } from 'luxon';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { useOgpDuplicateShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-duplicate-shift';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { paths } from 'ogp/paths';
import { useCallback, useMemo } from 'react';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import {
  isFreelanceShift,
  isTempworkShift,
  ShiftDetailResponse,
} from 'shared/services/shift-service-types';
import { transformData } from '../../utils/transform-data';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { OgpShiftForm } from '../ogp-shift-form';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';

type ShiftDuplicateProps = {
  shift: ShiftDetailResponse;
  shiftId: string;
};

export const OgpShiftDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(id);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  return <ShiftDuplicate shift={shiftQuery.data} shiftId={id} />;
};

const ShiftDuplicate = ({ shift, shiftId }: ShiftDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const updatePlanningPath = useUpdatePlanningPath();
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const shiftDateTitle = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd').toFormat('dd-LL-yyyy');
  const mutation = useOgpDuplicateShift();

  const onCancel = useCallback(
    () =>
      navigate(
        generatePath(paths.Shifts, {
          search: createSearchParams({ shiftDetailId: shiftId }).toString(),
        })
      ),
    [navigate, generatePath, shiftId]
  );

  const shiftData: Partial<ShiftDetailResponse> | undefined = useMemo(() => {
    if (isFreelanceShift(shift) && isTempworkShift(shift)) {
      return {
        ...shift,
        hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
        mutableUntilHours: shift.freelanceProperties.mutableUntilMinutes / 60,
        grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
        jobId: shift.job.id,
        departmentId: shift.job.department.id,
      };
    }
    if (isFreelanceShift(shift)) {
      return {
        ...shift,
        hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
        mutableUntilHours: shift.freelanceProperties.mutableUntilMinutes / 60,
        jobId: shift.job.id,
        departmentId: shift.job.department.id,
      };
    }
    if (isTempworkShift(shift)) {
      return {
        ...shift,
        grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
        jobId: shift.job.id,
        departmentId: shift.job.department.id,
      };
    }
  }, [shift]);

  const formMethods = useForm<FreelanceShiftFormData | TempWorkShiftFormData>({
    defaultValues: { ...shiftData, jobId: undefined },
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: FreelanceShiftFormData | TempWorkShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate(
        { ...data, ancestorShiftId: shiftId },
        {
          onSuccess: (shiftId, payload) => {
            const shiftDetailPath = updatePlanningPath({
              shiftDetailId: shiftId,
              date: DateTime.fromISO(payload.startDate),
            });
            alertSuccess(alerts.success.created);
            navigate(shiftDetailPath);
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  }, [formMethods, mutation]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateTitle} dupliceren`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <OgpShiftForm mode="duplicate" />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </FormProvider>
  );
};
