import { ApproveIdCheck } from '@types';
import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions } from 'react-query';

type MutationData = ApproveIdCheck;

export const useApproveFlexWorkerIdentityCheck = (
  identityVerificationId: string = '',
  options: UseMutationOptions<unknown, unknown, MutationData>
) => {
  const mutationFn = async (approveIdentityCheckPayload: ApproveIdCheck) =>
    await flexWorkerService.approveFlexWorkerIdCheck(
      identityVerificationId,
      approveIdentityCheckPayload
    );
  return useMutation(mutationFn, options);
};
