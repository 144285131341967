import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { useOgpMultiplyShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-multiply-shift';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { pluralizeWithValue } from 'shared/utils/pluralize';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyRelativePath } from 'ogp/hooks';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { paths } from 'ogp/paths';
import {
  isFreelanceShift,
  isTempworkShift,
  ShiftDetailResponse,
} from 'shared/services/shift-service-types';
import { ShiftMultiplyCalendar } from '../../components/shift-multiply-calendar/shift-multiply-calendar';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { OgpShiftForm } from '../ogp-shift-form';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';

type ShiftMultiplyProps = {
  shift: ShiftDetailResponse;
  shiftId: string;
};

export const OgpShiftMultiplyDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(id);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  return <ShiftMultiply shift={shiftQuery.data} shiftId={id} />;
};

const ShiftMultiply = ({ shift, shiftId }: ShiftMultiplyProps) => {
  const classes = getClasses();
  const generatePath = useCompanyRelativePath();
  const updatePlanningPath = useUpdatePlanningPath();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const [value, setValue] = useState<DateTime[]>();

  const dates = useMemo(() => value ?? [], [value]);
  const dateCount = useMemo(() => dates.length, [dates.length]);
  const shiftStartDate = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd');
  const shiftDateLabel = shiftStartDate.toFormat('dd-LL-yyyy');

  const mutation = useOgpMultiplyShift({
    onSuccess: () => {
      const shiftDetailPath = updatePlanningPath({
        date: dates[0],
      });
      closeDialog();
      alertSuccess(alerts.success.multiplied);
      navigate(shiftDetailPath);
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const handleSubmit = useCallback(() => {
    openDialog(
      {
        title: 'Shift dupliceren',
        content: `Weet je zeker dat je ${pluralizeWithValue(
          dateCount,
          'nieuwe shift',
          'nieuwe shifts'
        )} wilt toevoegen met deze gegevens?`,
        primaryButton: (props) => <PrimaryButton {...props}>Opslaan</PrimaryButton>,
        secondaryButton: (props) => <SecondaryButton {...props}>Annuleren</SecondaryButton>,
      },
      () => {
        disableDialog();
        mutation.mutate({
          id: shiftId,
          startDates: dates.map((date) => date.toFormat('yyyy-LL-dd')),
        });
      }
    );
  }, [dateCount, dates, disableDialog, mutation, openDialog, shift.job.id, shiftId]);

  const onCancel = useCallback(
    () =>
      navigate(
        generatePath(paths.Shifts, {
          search: createSearchParams({ shiftDetailId: shiftId }).toString(),
        })
      ),
    [navigate, generatePath, shiftId]
  );

  const shiftData: Partial<FreelanceShiftFormData | TempWorkShiftFormData> | undefined =
    useMemo(() => {
      if (isFreelanceShift(shift) && isTempworkShift(shift)) {
        return {
          ...shift,
          hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
          grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
          mutableUntilHours: shift.freelanceProperties.mutableUntilMinutes / 60,
          jobId: shift.job.id,
          departmentId: shift.job.department.id,
        };
      }
      if (isFreelanceShift(shift)) {
        return {
          ...shift,
          hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
          mutableUntilHours: shift.freelanceProperties.mutableUntilMinutes / 60,
          jobId: shift.job.id,
          departmentId: shift.job.department.id,
        };
      }
      if (isTempworkShift(shift)) {
        return {
          ...shift,
          grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
          jobId: shift.job.id,
          departmentId: shift.job.department.id,
        };
      }
    }, [shift]);

  const formMethods = useForm<FreelanceShiftFormData | TempWorkShiftFormData>({
    defaultValues: { ...shiftData, jobId: undefined },
  });

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateLabel} in bulk aanmaken`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
            disabledPrimaryButton={dateCount < 1}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OgpShiftForm mode={'read'} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.calendarExplainer}>
              Selecteer de dagen waarop je de shift nog meer wilt laten plaatsvinden.
            </Typography>
            <ShiftMultiplyCalendar shiftDate={shiftStartDate} onChange={setValue} />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};

const getClasses = makeStyles((theme) => ({
  calendarExplainer: {
    marginBottom: theme.spacing(1),
  },
}));
