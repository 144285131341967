import { CompanyData } from '@types';
import { CompanyFormData } from './company-form';

export const companyDataPrepareForSubmit = (data: CompanyFormData): CompanyData => {
  const { receiptCertificates, ...rest } = data;
  const processedData = {
    ...rest,
    receiptCertificateIds: receiptCertificates
      ? receiptCertificates.map((certificate) => certificate.id)
      : [],
  };

  return processedData;
};
