import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import { useState } from 'react';
import {
  AddButton,
  BlockLink,
  createBlock,
  LwBlock,
  LwBlockGridContainer,
  Page,
  SearchBar,
  Toolbox,
} from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { IconBranchOffices } from 'assets/img';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'Routes';
import { usePageProvider } from 'ogp/layouts/planning-layout';
import { useGetDepartmentsAsBlocks } from './hooks';
import { BranchOfficeSelect } from '../../planning/components/branch-office-select';

const Departments = () => {
  const generatePath = useCompanyRelativePath();

  const [filter, setFilter] = useState('');
  const [{ filters }] = usePageProvider();
  const { refetch, status, data, error } = useGetDepartmentsAsBlocks(filters.branchOfficeId);

  const blocks = data?.map((block) => createBlock(block));

  const filtered = blocks?.filter((dpt) => {
    return dpt.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });

  return (
    <Page
      header={
        <HeaderStatistics
          titleText="Afdelingen"
          subheaderText="Hier zie je alle afdelingen binnen jouw bedrijf"
          type="departments"
        />
      }
      toolbox={
        <Toolbox>
          <AddButton>Voeg afdeling toe</AddButton>
          <SearchBar
            onChange={setFilter}
            initialValue={filter}
            placeholder="Filter afdelingen op naam"
          />
          <Toolbox.PushRight>
            <BranchOfficeSelect />
          </Toolbox.PushRight>
        </Toolbox>
      }
    >
      {!filtered ? (
        <RenderUnsettledUI data={{ error, refetch, status }} />
      ) : filtered.length === 0 ? (
        <Typography>Er zijn geen departments die aan je filter voldoen.</Typography>
      ) : (
        <LwBlockGridContainer container spacing={4}>
          {filtered.map((block) => (
            <Grid item xs={6} md={4} lg={3} key={block.id}>
              <BlockLink to={generatePath(routes.DepartmentDetail, { id: block.id }, filters)}>
                <LwBlock
                  icon={<IconBranchOffices width={24} height={24} />}
                  severity={block.type?.severity}
                  id={block.id}
                  title={block.title}
                  subtitle={block.subtitle}
                  dataBlocks={block.dataBlocks}
                />
              </BlockLink>
            </Grid>
          ))}
        </LwBlockGridContainer>
      )}
    </Page>
  );
};

export { Departments };
