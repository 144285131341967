import { flexPoolService } from 'ogp/services';
import { useQuery } from 'react-query';
import { useAlert } from 'shared/components/alerts';

type AvailableFlexWorkerForFlexPool = { id: string; email: string };

export const useGetAvailableFlexWorkersForFlexPool = (flexPoolId: string, filter: string) => {
  const { alertError } = useAlert();
  return useQuery<
    Promise<AvailableFlexWorkerForFlexPool[]>,
    Error,
    AvailableFlexWorkerForFlexPool[]
  >(filter, async () => await flexPoolService.getAvailableFlexWorkers(flexPoolId, filter), {
    enabled: filter.length >= 3,
    keepPreviousData: true,
    staleTime: Infinity,
    onError: (e) => alertError(e),
  });
};
