import type { PrebuiltBlock } from 'redesign';
import {
  CreateDepartmentResponse,
  DepartmentPayload,
  GetDepartmentResponse,
  GetDepartmentsResponse,
  UpdateDepartmentPayload,
} from './department-service.types';
import { OgpApiService } from './ogp-api-service';

export class DepartmentService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public async createDepartment(department: DepartmentPayload) {
    const result = await this.ogpApiService.create<CreateDepartmentResponse>({
      path: 'departments',
      body: department,
    });

    return result;
  }

  public async updateDepartment(departmentId: string, department: UpdateDepartmentPayload) {
    const result = await this.ogpApiService.update({
      path: `departments/${departmentId}`,
      body: department,
    });

    return result;
  }

  public async getDepartment(departmentId: string) {
    const result = await this.ogpApiService.get<GetDepartmentResponse>({
      path: `departments/${departmentId}`,
    });

    return result;
  }

  public async getDepartments(params: { includeArchived?: boolean }) {
    const result = await this.ogpApiService.get<GetDepartmentsResponse>({
      path: 'departments',
      params,
    });

    return result;
  }

  public async getDepartmentList(branchOfficeId?: string) {
    const response = await this.ogpApiService.get<PrebuiltBlock[]>({
      path: 'departments/listing',
      params: { includeArchived: false, branchOfficeId },
    });

    return response;
  }

  public async archiveDepartment(departmentId: string) {
    return await this.ogpApiService.delete<undefined>({
      path: `departments/${departmentId}`,
    });
  }
}
