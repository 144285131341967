import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { AccreditationFormTypes } from './accreditation-form-hq';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';
import {
  PrimaryButton,
  SecondaryButton,
  useDialog,
} from '../../../../../ogp/providers/dialog-provider';
import { accreditationsAlerts, useAlert } from '../../../../../shared/components/alerts';
import { useUpdateAccreditation } from '../hooks/use-update-accreditation';
import { IconApproved, IconCancel } from '../../../../../assets/img';
import { LwButton } from '../../../../../redesign';
import { hqRoutes } from '../../../../Routes';
import { AccreditationType } from '../../../../services/accreditations-service.types';

export const EditAccreditationToolboxHQ = () => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useUpdateAccreditation();
  const { watch, formState } = useFormContext<AccreditationFormTypes>();

  const { certificate, flexWorkerOption } = watch();

  const { expirationDate, accreditations, startDate } = watch();

  const updateAccreditation = () => {
    const accreditation = accreditations?.find((a) => a.id === certificate);

    openDialog(
      {
        title: expirationDate
          ? 'Wil je deze accreditatie updaten?'
          : 'Weet je zeker dat je deze accreditatie zonder vervaldatum wilt updaten?',
        secondaryButton: (props) => (
          <SecondaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </SecondaryButton>
        ),
        primaryButton: (props) => (
          <PrimaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </PrimaryButton>
        ),
      },
      () => {
        disableDialog();
        const mutationData = {
          flexWorkerId: flexWorkerOption?.value || '',
          certificateId: certificate || '',
          expirationDate,
          startDate,
          accreditations: accreditation ? [accreditation] : [],
          accreditationType: AccreditationType.FULL,
        };

        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            alertSuccess(accreditationsAlerts.success.updated);
            navigate(generatePath(hqRoutes.Accreditations));
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };

  return (
    <>
      <LwButton
        type="submit"
        iconColorMode="stroke"
        color="primary"
        startIcon={<IconApproved />}
        onClick={updateAccreditation}
        disabled={!formState.isDirty}
      >
        Aanpassen
      </LwButton>
      <LwButton
        onClick={() => navigate(generatePath(hqRoutes.Accreditations))}
        iconColorMode="fill"
        color="secondary"
        startIcon={<IconCancel />}
      >
        Annuleer
      </LwButton>
    </>
  );
};
