import {
  BreakOption,
  CompanyBasicInfo,
  CompanyDetailAndJobGroups,
  CompanyEmploymentTypes,
  CompanyFormPayload,
} from '@types';

import { OgpApiService } from './ogp-api-service';
import {
  FlexWorkerForNotWelcomeList,
  GetBreakOptionsResponse,
  GetFlexWorkerForNotWelcomeListResponse,
  GetNotWelcomeListResponse,
} from './company-service.types';

export class CompanyService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  // Get only basic company information
  public getCompanyBasicInfo = async () => {
    const companyBasicInfo = await this.ogpApiService.get<CompanyBasicInfo>({
      path: `companies/basic-info/`,
    });
    return companyBasicInfo;
  };

  /**
   * Get a single company and its related jobGroups
   */
  public getCompanyDetailAndJobGroups = async () => {
    const companyDetailAndJobGroups = await this.ogpApiService.get<CompanyDetailAndJobGroups>({
      path: `companies/`,
    });

    return companyDetailAndJobGroups;
  };

  public getCompanyEmploymentTypes = async () => {
    const companyEmploymentTypes = await this.ogpApiService.get<CompanyEmploymentTypes>({
      path: `companies/employment-types/`,
    });

    return companyEmploymentTypes;
  };

  /**
   * Get a single company
   */
  public getBreakOptions = async (): Promise<BreakOption[]> => {
    const response = await this.ogpApiService.get<GetBreakOptionsResponse>({
      path: `companies/break-options`,
    });

    return response.breakOptions;
  };

  /**
   * Update an existing company
   */
  public updateCompany = async (companyId: string, company: CompanyFormPayload) => {
    return await this.ogpApiService.update({
      path: `companies/${companyId}`,
      body: company,
    });
  };

  /**
   * Returns a list of flexworkers that are on the 'not welcome list' for a company
   */
  public getNotWelcomeList = async () => {
    const response = await this.ogpApiService.get({ path: 'not-welcome-list' });
    const result = GetNotWelcomeListResponse.parse(response);
    return result;
  };

  /**
   * Returns a list of flexworkers can be added on the 'not welcome list' for a company
   */
  public getAvailableFlexWorkers = async (filter: string, signal: AbortSignal | undefined) => {
    const response = await this.ogpApiService.get<FlexWorkerForNotWelcomeList[]>({
      path: 'not-welcome-list/available',
      params: { filter },
      signal,
    });

    const result = GetFlexWorkerForNotWelcomeListResponse.parse(response);
    return result;
  };

  /**
   * Returns a list of flexworkers that are on the 'not welcome list' for a company
   */
  public addFlexWorkerToNotWelcomeList = async (
    flexWorkerId: string,
    reason: string,
    departmentIds: string[]
  ) => {
    return await this.ogpApiService.create<undefined>({
      path: `not-welcome-list/add/${flexWorkerId}`,
      body: { reason, departmentIds },
    });
  };

  /**
   * Returns a list of flexworkers can be added on the 'not welcome list' for a company
   */
  public removeFlexWorkerFromNotWelcomeList = async (flexWorkerId: string) => {
    return await this.ogpApiService.delete<undefined>({
      path: `not-welcome-list/remove/${flexWorkerId}`,
    });
  };
}
