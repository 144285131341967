import { useMemo } from 'react';
import { Table } from 'redesign';
import { getDepartmentsColumnDefinition } from './get-departments-column-definition';
import { getDepartmentsTableData } from './get-departments-table-data';

export const Departments = ({ departments }: { departments: string[] }) => {
  const columns = useMemo(() => getDepartmentsColumnDefinition(), []);
  const tableData = useMemo(() => getDepartmentsTableData(departments), [departments]);

  return <Table stickyHeader data={tableData} columns={[columns.name]} />;
};
