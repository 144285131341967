import { Certificate } from '@types';

export type AccreditationDataHQ = {
  createdAt: string;
  updatedAt: string;
  expirationDate: string;
  state: string;
  fileUrl: string;
  certificateId: string;
  flexWorkerId: string;
  certificate: Certificate;
};

export type RawAccreditationData = {
  createdAt: string;
  updatedAt: string;
  startDate: string | null;
  expirationDate: string | null;
  state: AccreditationState;
  type: AccreditationType;
  version: number;
  fileUrl?: string;
  certificateId: string;
  flexWorkerId: string;
  certificate: Certificate;
  flexWorker: {
    id: string;
    imageUrl?: string;
    phone: string;
    email: string;
    firstName: string;
    infix: string;
    lastName: string;
  };
};

export enum AccreditationType {
  FULL = 'full',
  RECEIPT = 'receipt',
}

export type AccreditationState = 'rejected' | 'verified' | 'pending';

export type HQCertificateWithFileUrl = Certificate & { fileUrl?: string };
