import type { OgpApiService } from './ogp-api-service';

// matches the return type of /ogp/no-show-reasons endpoint
export type NoShowReason = {
  id: string;
  longDescription: string;
  shortDescription: string;
  enabledForSelection: boolean;
  allowsConsequences: boolean;
  affectsAttendanceRate: boolean;
};

export class NoShowReasonService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public getList = async () => {
    const noShowReasons = await this.ogpApiService.get<NoShowReason[]>({
      path: `no-show-reasons`,
    });

    return noShowReasons;
  };
}
