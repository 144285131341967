import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { BlockLink, BlockProps, LwBlock, LwBlockGridContainer } from 'redesign';
import { RenderUnsettledUI } from '../../../../../shared/components';
import { useGetUsers } from '../hooks/query/use-get-users';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';
import { IconUser } from '../../../../../assets/img';
import { hqRoutes } from '../../../../Routes';
import { GetHqUsers } from '../../../../services/user-service.types';

export const HqUserList = ({ search }: { search: string }) => {
  const usersQuery = useGetUsers();
  if (usersQuery.status !== 'success') {
    return <RenderUnsettledUI data={usersQuery} />;
  }

  return <HqUserListImpl data={usersQuery.data} search={search} />;
};

const HqUserListImpl = ({ data, search }: { data: GetHqUsers[]; search: string }) => {
  const generatePath = useHqRelativePath();

  const filtered = useMemo(() => {
    if (!search) {
      return data;
    }

    return data.filter((user) => {
      const fullName = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;
      return fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    });
  }, [data, search]);

  const blocks: BlockProps[] = filtered.map((user) => {
    return {
      id: user.id,
      title: `${user.firstName} ${user.lastName}`,
    };
  });

  if (!blocks.length) {
    return <Typography>Er zijn geen gebruikers die aan je filter voldoen.</Typography>;
  }

  return (
    <LwBlockGridContainer>
      {blocks.map((block) => (
        <Grid item xs={6} md={4} lg={3} key={block.id}>
          <BlockLink to={generatePath(hqRoutes.HQUsersDetail, { id: block.id }, { user: search })}>
            <LwBlock
              icon={<IconUser width={24} height={24} />}
              id={block.id}
              severity={block.severity}
              title={block.title}
              subtitle={block.subtitle}
              dataBlocks={block.dataBlocks}
            />
          </BlockLink>
        </Grid>
      ))}
    </LwBlockGridContainer>
  );
};
