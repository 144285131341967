import React from 'react';
import {
  AccreditationInput,
  AutocompleteOption,
  FormCheckbox,
  LwFormDate,
  LwFormSelect,
} from 'redesign';
import { Box, Grid, styled, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { HQCertificateWithFileUrl } from 'hq/services/accreditations-service.types';
import { WorkerList } from 'hq/services/flex-worker-service.types';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { Section } from 'shared/components/common/form-section';
import { IconInfoRedesigned } from 'assets/img';
import { FlexWorkerSelect, FlexWorkerSelectStateProps } from '../../flexWorkers/flex-worker-select';
import { State } from '../accreditations.types';

export type AccreditationFormTypes = {
  // TODO refactor this. "accreditations" is the wrong name and is confusing, these are just files with a certificate id. https://levelworks.atlassian.net/browse/LW1-17607
  accreditations: AccreditationFormRepresentation[] | null;
  certificate: string | null;
  startDate: DateTime | null;
  isWithoutDocument: boolean;
  expirationDate: DateTime | null;
  flexWorkerOption: AutocompleteOption | null;
  hasPreviouslyUploadedDocument: boolean;
  accreditationState: State;
};

type AccreditationFormProps = FlexWorkerSelectStateProps & {
  certificates: HQCertificateWithFileUrl[];
  flexWorkers: WorkerList[];
  formState: 'create' | 'update';
};

export const AccreditationFormHQ = (props: AccreditationFormProps) => {
  const formMethods = useFormContext<AccreditationFormTypes>();

  const { isWithoutDocument, certificate, hasPreviouslyUploadedDocument, accreditationState } =
    formMethods.watch();

  return (
    <Box width={'100%'}>
      <Grid container>
        {props.formState === 'update' && (
          <Section sectionLabel="Status">
            <Grid item xs={4}>
              <AccreditationState variant="body1">
                Huidige staat: {accreditationState}
              </AccreditationState>
            </Grid>
          </Section>
        )}
        <Section sectionLabel="Kies een flexwerker">
          <Grid item xs={4}>
            <FlexWorkerSelect
              workers={props.flexWorkers}
              // TODO create an own provider and not pass these props LW1-16844
              flexWorkerOption={props.flexWorkerOption}
              flexWorkerSearch={props.flexWorkerSearch}
              setFlexWorkerSearch={props.setFlexWorkerSearch}
              setFlexWorkerOption={props.setFlexWorkerOption}
              disabled={props.formState === 'update'}
            />
          </Grid>
        </Section>
        <Section sectionLabel="Certificaat uploaden">
          <Grid item xs={4}>
            <Box display="flex" flex={1}>
              <AccreditationInput
                certificates={props.certificates}
                // TODO refactor this. "accreditations" is the wrong name and is confusing, these are just files with a certificate id. https://levelworks.atlassian.net/browse/LW1-17607
                name="accreditations"
                required={!isWithoutDocument}
                fileSelectorDisabled={isWithoutDocument}
                selectedCertificateId={certificate || undefined}
                certificateSelectorDisabled={props.formState === 'update'}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <LwFormDate
              name="expirationDate"
              label="Vervaldatum"
              control={formMethods.control}
              minDate={DateTime.now().toISODate()}
              disabled={isWithoutDocument}
            />
          </Grid>
        </Section>
        <Section sectionLabel="Certificaat zonder document">
          <Grid item xs={6}>
            <FormCheckbox
              name="isWithoutDocument"
              label="Een certificaat aanmaken zonder certificaat document"
              control={formMethods.control}
              disabled={hasPreviouslyUploadedDocument}
            />
            <Box display="flex" alignItems="center" gap={4} mt={2}>
              <IconInfoRedesigned width={24} height={24} /> Je moet een startdatum instellen en het
              certificaat document uploaden na de startdatum
            </Box>
          </Grid>
          <Grid item xs={4}>
            <LwFormSelect
              control={formMethods.control}
              name="certificate"
              label="Certificaten"
              options={props.certificates.map((certificate) => ({
                value: certificate.id,
                label: certificate.name,
              }))}
              rules={{ required: isWithoutDocument }}
              disabled={!isWithoutDocument}
            ></LwFormSelect>
          </Grid>
          <Grid container item xs={4} spacing={4}>
            <Grid item xs={6}>
              <LwFormDate
                name="startDate"
                label="Startdatum"
                control={formMethods.control}
                minDate={DateTime.now().toISODate()}
                rules={{ required: isWithoutDocument ? 'Voer een startdatum in' : false }}
                disabled={!isWithoutDocument}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormDate
                name="expirationDate"
                label="Vervaldatum"
                control={formMethods.control}
                minDate={DateTime.now().toISODate()}
                disabled={!isWithoutDocument}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
    </Box>
  );
};

const AccreditationState = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));
