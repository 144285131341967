import { Box, Grid, GridOwnProps, styled } from '@mui/material';

export const LwBlockGridContainer = ({
  children,
  ...props
}: { children: React.ReactNode } & GridOwnProps) => {
  return (
    <Box width="100%">
      <StyledGridContainer container spacing={4} {...props}>
        {children}
      </StyledGridContainer>
    </Box>
  );
};

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginTop: 0,
  width: '100%',
  paddingBottom: theme.spacing(2),
}));
