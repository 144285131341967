import { useMutation, useQueryClient } from 'react-query';
import { partnerWorkerService } from 'partner/shared/services';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { PartnerWorkerFormValues } from '../partner-worker-form.types';

type UpdatePartnerWorkerParams = {
  partnerWorkerId: string;
  payload: PartnerWorkerFormValues;
};

export const useUpdatePartnerWorker = () => {
  const mutationFn = async ({ partnerWorkerId, payload }: UpdatePartnerWorkerParams) => {
    await partnerWorkerService.updatePartnerWorker(partnerWorkerId, {
      firstName: payload.firstName,
      infix: payload.infix,
      lastName: payload.lastName,
      email: payload.email,
      phone: payload.phone,
      accreditations: payload.accreditations,
      employmentType: payload.employmentType,
      employmentId: payload.employmentId ? payload.employmentId : undefined,
    });
  };
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerWorker(variables.partnerWorkerId));
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.checkouts());
    },
  });
};
