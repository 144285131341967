import { Box } from '@mui/material';
import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { routes } from 'ogp/Routes';
import { flexPoolAlerts as alerts, useAlert } from 'shared/components/alerts';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateFlexPool } from './hooks/use-create-flex-pool';
import { useGetFlexpoolManagementData } from './hooks/use-flex-pool-management-data';
import { FlexPoolForm } from '../shared/components';
import {
  CreateFlexPoolPayload,
  FlexpoolManagementData,
  FlexPoolManagementFormData,
} from '../../../../../services/flex-pool-service.types';
import { invariant } from '../../../../../../shared/utils/utils';
import { RenderUnsettledUI } from '../../../../../../shared/components';

const FlexPoolCreateDataProvider = () => {
  const userInfo = useGetOGPUserInfo();
  const companyId = userInfo.data?.userInfo.company.slug;
  invariant(companyId, 'Company id is missing');
  const managementQuery = useGetFlexpoolManagementData(companyId);

  if (managementQuery.status !== 'success') {
    return <RenderUnsettledUI data={managementQuery} />;
  }

  return <FlexPoolCreate managementData={managementQuery.data} />;
};

const FlexPoolCreate = ({ managementData }: { managementData: FlexpoolManagementData[] }) => {
  const generatePath = useCompanyRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<FlexPoolManagementFormData>();
  const navigate = useNavigate();
  const mutation = useCreateFlexPool({
    onSuccess: () => {
      alertSuccess(alerts.success.created);
      navigate(generatePath(routes.FlexPools));
    },
    onError: (e) => alertError(e),
  });
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      const payload: CreateFlexPoolPayload = {
        name: data.name,
        description: data.description,
        departmentIds: Object.entries(data.departments).reduce<string[]>(
          (acc, [key, value]) => (value ? [...acc, key] : acc),
          []
        ),
        jobIds: data.jobs?.map((i) => i.id) ?? [],
      };
      mutation.mutate(payload);
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe flexpool aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={returnTo(routes.FlexPools)}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <Box width={'100%'}>
          <FlexPoolForm state="create" managementData={managementData} />
        </Box>
      </Page>
    </FormProvider>
  );
};

export { FlexPoolCreateDataProvider, FlexPoolCreate };
