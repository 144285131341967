import { TakeDefined } from '@types';
import { FlexPoolsTableColumnDefinition } from './flex-pools-table.types';
import { useGetFlexPools } from '../../../hooks/use-get-flex-pools';

export const getFlexPoolsTableData = (
  data: TakeDefined<ReturnType<typeof useGetFlexPools>['data']>
) => {
  const result: FlexPoolsTableColumnDefinition[] = data.items.map((item) => {
    return {
      id: item.id,
      allJobsArchived: item.allJobsArchived,
      flexPoolName: item.name,
      departmentNames: item.departmentNames,
      flexWorkerCount: item.flexWorkerCount,
    };
  });
  return result;
};
