import { HQGetFlexWorkerDetailsResponse } from '@types';
import { Box, Divider } from '@mui/material';
import { FlexWorkerContractHead } from './flex-worker-contract-head';
import { FlexWorkerContractBody } from './flex-worker-contract-body';

type Props = {
  data: HQGetFlexWorkerDetailsResponse;
  onClose: () => void;
};

export const FlexWorkerContract = (props: Props) => {
  return (
    <Box padding={8} display={'flex'} flexDirection="column" height="100%">
      <FlexWorkerContractHead data={props.data} onClose={props.onClose} />
      <Divider />
      <FlexWorkerContractBody data={props.data} onClose={props.onClose} />
    </Box>
  );
};
