import { z } from 'zod';
import { BreakOption } from '@types';

const GetNotWelcomeListItem = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  infix: z.string(),
  reason: z.string(),
  dateAdded: z.string(),
  departments: z.array(z.string()),
});

export const GetNotWelcomeListResponse = z.array(GetNotWelcomeListItem);

export type NotWelcomeListItem = z.infer<typeof GetNotWelcomeListItem>;

const GetFlexWorkerForNotWelcomeList = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  infix: z.string().optional().nullable(),
  email: z.string(),
});

export const GetFlexWorkerForNotWelcomeListResponse = z.array(GetFlexWorkerForNotWelcomeList);
export type FlexWorkerForNotWelcomeList = z.infer<typeof GetFlexWorkerForNotWelcomeList>;

export type GetBreakOptionsResponse = {
  breakOptions: BreakOption[];
};
