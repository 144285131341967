import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { accreditationsService } from 'hq/services';
import { useQuery } from 'react-query';
import {
  AccreditationType,
  RawAccreditationData,
} from '../../../../services/accreditations-service.types';

const useGetAccreditationDetail = (
  certificateId: string,
  flexWorkerId: string,
  accreditationType: AccreditationType
) => {
  const queryFn = async () =>
    await accreditationsService.getAccreditationDetail(
      certificateId,
      flexWorkerId,
      accreditationType
    );

  return useQuery<RawAccreditationData, Error>(QUERY_KEYS_HQ.accreditationDetail(), queryFn);
};

export { useGetAccreditationDetail };
