import type { Column } from 'react-table';
import SortIcon from 'assets/img/icons/caret-down.svg?react';

import { Button, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ContextMenuButton, LevelTableHeaderProps } from 'redesign';
import { State, translateState } from './accreditations.types';
import {
  AccreditationCellProp,
  AccreditationsColumnDefinition,
  AccreditationTableDefinition,
} from './accreditations-table.types';

const getAccreditationsColumnDefinition = (
  filteredState: State
): Column<AccreditationsColumnDefinition>[] => {
  const flexWorkerName: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Naam flexwerker</Header>,
    accessor: 'flexWorkerName',
    Cell: FlexWorkerNameCell,
  };
  const certificate: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Certificaat</Header>,
    accessor: 'certificate',
    Cell: CertificateCell,
  };
  const certificateType: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Certificaattype</Header>,
    accessor: 'type',
    Cell: CertificateTypeCell,
  };
  const submittedDate: Column<AccreditationsColumnDefinition> = {
    Header: SubmittedDateHeaderCell,
    accessor: 'submittedDate',
    Cell: SubmittedDateCell,
  };

  const state: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Status</Header>,
    accessor: 'state',
    Cell: () => <Typography variant="subtitle2">{translateState(filteredState)}</Typography>,
  };

  const actions: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Actie</Header>,
    accessor: 'actions',
    Cell: ActionCell,
  };
  return [flexWorkerName, certificate, certificateType, state, submittedDate, actions];
};

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" color="secondary" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const SubmittedDateHeaderCell = (
  cell: LevelTableHeaderProps<AccreditationsColumnDefinition, AccreditationTableDefinition>
) => {
  if (!cell.data[0]) {
    return null;
  }

  return (
    <Button
      variant="text"
      onClick={() => {
        cell.onSortClick();
      }}
    >
      <Header>Datum van toevoeging</Header>
      <StyledSortIcon
        style={{
          transform: cell.sortState === 'asc' ? 'rotate(180deg)' : '',
        }}
      />
    </Button>
  );
};

const FlexWorkerNameCell = (cell: AccreditationCellProp<'flexWorkerName'>) => {
  return (
    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
      {cell.value}
    </Typography>
  );
};

const CertificateCell = (cell: AccreditationCellProp<'certificate'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const CertificateTypeCell = (cell: AccreditationCellProp<'type'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const SubmittedDateCell = (cell: AccreditationCellProp<'submittedDate'>) => {
  const date = DateTime.fromISO(cell.value).setLocale('nl');
  return (
    <Typography variant="subtitle2" style={{ marginLeft: '32px' }}>
      {date.toFormat('ccc, dd-LL-yy')}
    </Typography>
  );
};

const ActionCell = (cell: AccreditationCellProp<'actions'>) => {
  const actions = cell.getRowActions(
    cell.value.flexWorkerId,
    cell.value.certificateId,
    cell.row.original.type
  );

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`accreditation-actions-menu-${cell.value.certificateId}`}>
      {actions}
    </ContextMenuButton>
  );
};

const StyledSortIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  height: '12px',
  width: '12px',
  transition: 'ease transform .5s',
}));

export { getAccreditationsColumnDefinition };
