import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Chip, Tooltip } from '@mui/material';
import { InputSelectOption } from '@types';
import { useForm } from 'react-hook-form';
import { AccreditationFormProps } from './accreditations-input.types';
import { IconAdd, IconCheckmark } from '../../../assets/img';
import { LwButton } from '../../button';
import { LwFormSelect } from '../select';

type AccreditationForm = {
  certificate: string;
};

export const AddAccreditationForm = ({
  certificates,
  setAccreditations,
  required,
  certificateSelectorDisabled,
  fileSelectorDisabled,
  selectedCertificateId,
}: AccreditationFormProps) => {
  const [selectedCertificate, setSelectedCertificate] = useState(selectedCertificateId || '');
  const [fileInput, setFileInput] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles = Array.from(event.target.files);
      setFiles(uploadedFiles);
    }
  };

  const methods = useForm<AccreditationForm>();

  useEffect(() => {
    if (selectedCertificateId) {
      // didnt use default value in useForm because these two values need to be in sync.
      setSelectedCertificate(selectedCertificateId);
      methods.setValue('certificate', selectedCertificateId);
    }
  });

  const options = certificates.map((certificate) => {
    return { value: certificate.id, label: certificate.name };
  }) as InputSelectOption[];

  const handleSubmit = useCallback(() => {
    if (!selectedCertificate) {
      return;
    }
    setAccreditations((accreditations) => [
      ...accreditations,
      { id: selectedCertificate, files: files },
    ]);
    setFiles([]);
    setFileInput('');
    setSelectedCertificate('');
  }, [selectedCertificate, files, setAccreditations]);

  return (
    <Box display="flex" flexDirection="column" gap={2} data-testid="add-accreditation-form">
      <LwFormSelect
        control={methods.control}
        name="certificate"
        label="Certificaten"
        options={options}
        rules={{ required }}
        disabled={certificateSelectorDisabled}
        onChange={(e) => {
          if (typeof e.target.value === 'string') {
            setSelectedCertificate(e.target.value);
          }
        }}
      ></LwFormSelect>
      <Box display="flex" gap="1em" justifyContent="space-between">
        <LwButton
          color="secondary"
          startIcon={<IconAdd />}
          tabIndex={-1}
          component="label"
          type="submit"
          data-testid="add-accreditation-form-file-select"
          disabled={fileSelectorDisabled}
        >
          Selecteer bestanden
          <VisuallyHiddenInput type="file" value={fileInput} onChange={handleFileUpload} multiple />
        </LwButton>
        <LwButton
          color="secondary"
          onClick={handleSubmit}
          iconVariant="outlined"
          startIcon={<IconCheckmark />}
          disabled={fileSelectorDisabled || files.length === 0 || !selectedCertificate}
          data-testid="add-accreditation-form-add"
        >
          Voeg toe
        </LwButton>
      </Box>
      <Box
        paddingTop={4}
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        flexWrap="wrap"
        paddingLeft={2}
        paddingRight={2}
      >
        {files.map((file) => (
          <Tooltip key={file.name} title={file.name.toLowerCase()}>
            <FileChip key={file.name.toLowerCase()} label={file.name.toLowerCase()} />
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};

const FileChip = styled(Chip)({
  maxWidth: '100px',
});

const VisuallyHiddenInput = styled('input')({
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
