import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useGetUserListing } from 'ogp/hooks/queryHooks/users/use-get-user-listing';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import {
  BlockLink,
  createBlock,
  LwBlock,
  LwBlockGridContainer,
  LwButton,
  Page,
  SearchBar,
  Toolbox,
} from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { IconAdd, IconUser } from 'assets/img';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'Routes';

const Users = () => {
  const generatePath = useCompanyRelativePath();
  const [filter, setFilter] = useState('');
  const { status, error, refetch, data } = useGetUserListing();

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }

  const blocks = data.map((block) => createBlock(block));

  const filtered = blocks.filter((user) => {
    return user.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });

  return (
    <Page
      header={
        <HeaderStatistics
          titleText="Gebruikers"
          subheaderText="Hier zie je alle gebruikers binnen jouw bedrijf"
          type="users"
        />
      }
      toolbox={
        <Toolbox>
          <LwButton to="create" color="primary" startIcon={<IconAdd />} iconColorMode="fill">
            Voeg gebruiker toe
          </LwButton>
          <SearchBar
            onChange={setFilter}
            initialValue={filter}
            placeholder="Filter gebruikers op naam"
          />
        </Toolbox>
      }
    >
      {filtered.length === 0 ? (
        <Typography>Er zijn geen gebruikers die aan je filter voldoen.</Typography>
      ) : (
        <LwBlockGridContainer container spacing={4} sx={{ marginBottom: '32px' }}>
          {filtered.map((block) => (
            <Grid item xs={6} md={4} lg={3} key={block.id}>
              <BlockLink to={generatePath(routes.UsersDetail, { id: block.id })}>
                <LwBlock
                  icon={<IconUser width={24} height={24} />}
                  id={block.id}
                  severity={block.type?.severity}
                  title={block.title}
                  subtitle={block.subtitle}
                  dataBlocks={block.dataBlocks}
                />
              </BlockLink>
            </Grid>
          ))}
        </LwBlockGridContainer>
      )}
    </Page>
  );
};

export { Users };
