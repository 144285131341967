import { ItemOf, VAT } from '@types';
import _ from 'lodash';
import type {
  GetShiftCreateDataResponse,
  GetShiftPlanningListItemsResponse,
} from '../shift-service.types';
import type {
  CompanyRole,
  DepartmentRole,
  GetOGPUserInfoResponse,
  GetUserResponse,
  UpdateUserPayload,
} from '../user-service.types';
import type { GetJobCreateDataResponse } from '../job-service.types';
import { GetDepartmentResponse, GetDepartmentsResponse } from '../department-service.types';
import { GetBranchOfficesResponse } from '../branch-office-service.types';

/** Planning **/

type Department = ItemOf<GetShiftPlanningListItemsResponse['planning']['departments']>;
type JobType = ItemOf<Department['jobTypes']>;
type Shift = ItemOf<JobType['shifts']>;

export type PlanningModel = Array<
  Pick<Department, 'id' | 'name'> & {
    jobTypes: Array<
      Pick<JobType, 'id' | 'name'> & {
        shifts: Array<
          Pick<
            Shift,
            | 'applicants'
            | 'claimants'
            | 'dateLabel'
            | 'jobName'
            | 'dateTime'
            | 'endTime'
            | 'id'
            | 'maxClaimants'
            | 'startTime'
            | 'flexPoolOnly'
          >
        >;
      }
    >;
  }
>;

const shiftPlanningListItemsResponse2PlanningModel = (
  input: GetShiftPlanningListItemsResponse
): PlanningModel => {
  const sorted = _.sortBy(
    input.planning.departments,
    [(department) => department.branchOffice.name.toLowerCase()],
    [(department) => department.name.toLowerCase()]
  );
  const result = sorted.map((dpt) => {
    const { id, name, branchOffice } = dpt;

    return {
      id,
      name: [branchOffice.name, name].join('\r\n'),
      jobTypes: dpt.jobTypes.map((jobType) => ({
        id: jobType.id,
        name: jobType.name,
        shifts: jobType.shifts.map((shift) => ({
          id: shift.id,
          dateLabel: shift.dateLabel,
          dateTime: shift.dateTime,
          jobName: shift.jobName,
          startTime: shift.startTime,
          endTime: shift.endTime,
          applicants: shift.applicants,
          claimants: shift.claimants,
          flexPoolOnly: shift.flexPoolOnly,
          maxClaimants: shift.maxClaimants,
        })),
      })),
    };
  });

  return result;
};

/** OGPUserInfo **/

type OGPUserInfoModel = {
  userInfo: {
    email: string;
    firstname: string;
    lastname: string;
    nickname: string;
    phone: string;
    position: string;
    company: GetOGPUserInfoResponse['userInfo']['company'];
    roles: GetOGPUserInfoResponse['userInfo']['roles'];
  };
};

const OGPUserInfoResponse2OGPUserInfoModel = (input: GetOGPUserInfoResponse): OGPUserInfoModel => {
  const {
    userInfo: { company, roles, ...rest },
  } = input;

  return {
    userInfo: {
      email: rest.email,
      firstname: rest.firstname,
      lastname: rest.lastname,
      nickname: rest.nickname,
      phone: rest.phone,
      position: rest.position,
      company,
      roles,
    },
  };
};

/** JobCreateDataResponse **/
export type JobCreateDataModel = {
  companyOrtProvided: boolean;
  jobCertificates: {
    id: string;
    name: string;
  }[];
  branchOfficesWithDepartments: {
    value: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  breakOptions: Array<
    Pick<ItemOf<GetJobCreateDataResponse['jobCreateData']['breakOptions']>, 'id' | 'label'> & {
      value: number;
    }
  >;
  jobGroups: GetJobCreateDataResponse['jobCreateData']['jobGroups'];
  jobRequirements: GetJobCreateDataResponse['jobCreateData']['jobRequirements'];
};

const jobCreateDataResponse2JobCreateDataModel = (
  input: GetJobCreateDataResponse
): JobCreateDataModel => {
  const {
    branchOfficeNames,
    breakOptions,
    jobGroups,
    companyOrtProvided,
    jobCertificates,
    jobRequirements,
  } = input.jobCreateData;

  return {
    companyOrtProvided,
    branchOfficesWithDepartments: _.sortBy(branchOfficeNames, (branchOffice) =>
      branchOffice.name.toLowerCase()
    ).map((bo) => ({
      value: bo.id,
      label: bo.name,
      options: _.sortBy(bo.departments, (branchOffice) => branchOffice.name.toLowerCase()).map(
        (dpt) => ({
          value: dpt.id,
          label: dpt.name,
        })
      ),
    })),
    jobCertificates: jobCertificates.map((jc) => ({
      id: jc.id,
      name: jc.name,
    })),
    breakOptions: breakOptions.map((bo) => ({
      id: bo.id,
      label: bo.label,
      value: bo.minutes,
    })),
    jobGroups: jobGroups.map((jg) => ({
      id: jg.id,
      name: jg.name,
      jobType: jg.jobType.map((jt) => ({
        id: jt.id,
        name: jt.name,
      })),
    })),
    jobRequirements: jobRequirements,
  };
};

type ShiftCreateDataModel = {
  branchOfficesWithDepartments: {
    value: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  breakOptions: Array<
    Pick<ItemOf<GetJobCreateDataResponse['jobCreateData']['breakOptions']>, 'id' | 'label'> & {
      value: number;
    }
  >;
};

const shiftCreateDataResponse2ShiftCreateDataModel = (
  input: GetShiftCreateDataResponse
): ShiftCreateDataModel => {
  const {
    shiftCreateData: { branchOfficeNames, breakOptions },
  } = input;

  return {
    branchOfficesWithDepartments: _.sortBy(branchOfficeNames, (branchOffice) =>
      branchOffice.name.toLowerCase()
    ).map((bo) => ({
      value: bo.id,
      label: bo.name,
      options: _.sortBy(bo.departments, (department) => department.name.toLowerCase()).map(
        (dpt) => ({
          value: dpt.id,
          label: dpt.name,
        })
      ),
    })),
    breakOptions: breakOptions.map((bo) => ({
      id: bo.id,
      label: bo.label,
      value: bo.minutes,
    })),
  };
};

type BranchOfficesWithDepartmentsModel = {
  id: string;
  name: string;
  departments: {
    id: string;
    name: string;
  }[];
}[];

const departmentsResponse2BranchOfficesWithDepartmentsModel = (
  input: GetDepartmentsResponse
): BranchOfficesWithDepartmentsModel => {
  const departments = input;
  const branchOfficesWithDepartments: ReturnType<
    typeof departmentsResponse2BranchOfficesWithDepartmentsModel
  > = [];

  _.sortBy(departments, (department) => department.name.toLowerCase()).forEach((department) => {
    const branchOfficeIndex = branchOfficesWithDepartments.findIndex(
      (branchOffice) => branchOffice.id === department.branchOffice.id
    );

    if (branchOfficeIndex === -1) {
      branchOfficesWithDepartments.push({
        id: department.branchOffice.id,
        name: department.branchOffice.name,
        departments: [{ id: department.id, name: department.name }],
      });
    } else {
      branchOfficesWithDepartments[branchOfficeIndex].departments.push({
        id: department.id,
        name: department.name,
      });
    }
  });

  return _.sortBy(branchOfficesWithDepartments, (branchOffice) => branchOffice.name.toLowerCase());
};

type DepartmentsSelectModel = {
  value: string;
  label: string;
  options: {
    value: string;
    label: string;
  }[];
}[];

const departmentsResponse2DepartmentsSelectModel = (
  input: GetDepartmentsResponse
): DepartmentsSelectModel => {
  const departments = input;
  const branchOfficesWithDepartments: DepartmentsSelectModel = [];

  _.sortBy(departments, (department) => department.name.toLowerCase()).forEach((dpt) => {
    const branchOfficeIndex = branchOfficesWithDepartments.findIndex(
      (bo) => bo.value === dpt.branchOffice.id
    );
    const department = {
      value: dpt.id,
      label: dpt.name,
    };

    if (branchOfficeIndex === -1) {
      branchOfficesWithDepartments.push({
        value: dpt.branchOffice.id,
        label: dpt.branchOffice.name,
        options: [department],
      });
    } else {
      branchOfficesWithDepartments[branchOfficeIndex].options.push(department);
    }
  });

  return _.sortBy(branchOfficesWithDepartments, (branchOffice) => branchOffice.label.toLowerCase());
};

type UserModel = {
  accessPhone?: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  nickName: string;
  position: string;
  archived: boolean;
  isArchivable: boolean;
  roles: {
    companyRole?: CompanyRole;
    departmentRoles: {
      branchOffice: {
        id: string;
        name: string;
      };
      departments: {
        id: string;
        name: string;
        role: DepartmentRole;
      }[];
    }[];
  };
};

const userResponse2UserModel = (input: GetUserResponse): UserModel => {
  const { ogpUser } = input;

  return {
    accessPhone: ogpUser.accessPhone,
    id: ogpUser.id,
    firstName: ogpUser.firstName,
    lastName: ogpUser.lastName,
    email: ogpUser.email,
    phone: ogpUser.phone,
    nickName: ogpUser.nickname,
    position: ogpUser.position,
    archived: ogpUser.archived,
    isArchivable: ogpUser.isArchivable,
    roles: {
      companyRole: ogpUser.roles.companyRole?.role,
      departmentRoles: ogpUser.roles.departmentRole.map((dr) => ({
        branchOffice: {
          id: dr.branchOffice.id,
          name: dr.branchOffice.name,
        },
        departments: dr.branchOffice.departments.map((dpt) => ({
          id: dpt.id,
          name: dpt.name,
          role: dpt.role,
        })),
      })),
    },
  };
};

type UserFormModel = Pick<
  UserModel,
  | 'accessPhone'
  | 'id'
  | 'email'
  | 'phone'
  | 'lastName'
  | 'nickName'
  | 'position'
  | 'archived'
  | 'firstName'
  | 'isArchivable'
> & {
  roles: {
    companyRole: boolean;
    departmentRoles: Record<string, DepartmentRole | null>;
  };
};

const userResponse2UserFormModel = (input: GetUserResponse): UserFormModel => {
  const { roles, ...model } = userResponse2UserModel(input);
  const departmentRoles: UserFormModel['roles']['departmentRoles'] = {};

  roles.departmentRoles.forEach((departmentRole) => {
    departmentRole.departments.forEach((department) => {
      departmentRoles[department.id] = department.role;
    });
  });

  return {
    ...model,
    roles: {
      companyRole: roles.companyRole ? true : false,
      departmentRoles,
    },
  };
};

const userModel2UserPayload =
  (data: { companyId: string }) =>
  (input: UserFormModel): UpdateUserPayload => {
    return {
      email: input.email,
      firstName: input.firstName,
      lastName: input.lastName,
      nickname: input.nickName,
      accessPhone: input.accessPhone,
      phone: input.phone,
      position: input.position,
      roles: {
        departmentIdRoles: input.roles.departmentRoles,
        companyRole: {
          role: input.roles.companyRole ? 'ADMIN' : null,
          companyId: data.companyId,
        },
      },
    };
  };

type DepartmentDetailModel = {
  companyNameForOVO: string | null;
  name: string;
  locationIndicator: string | null;
  location?: {
    id: string;
    address: string;
    latitude: number;
    longitude: number;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: string;
    createdAt: string;
    updatedAt: string;
    isVerified: boolean;
  };
  legalPerson: string | null;
  locationUrl: string | null;
  costCenter: string | null;
  cocNumber: string | null;
  VAT: VAT;
  billingEntity?: {
    id: string;
    name: string;
  };
  flexPools: { id: string; name: string }[];
  departmentFlexPools?: { id: string; name: string }[];
  id: string;
  isArchivable: boolean;
};

const departmentResponse2DepartmentDetailModel = (
  input: GetDepartmentResponse
): DepartmentDetailModel => {
  const { department } = input;

  const jobsWithPlacements: string[] = [];

  department.job?.forEach((job) => jobsWithPlacements.push(job.id));

  return {
    billingEntity: department.billingEntity,
    locationIndicator: department.locationIndicator,
    companyNameForOVO: department.companyNameForOVO,
    cocNumber: department.cocNumber,
    name: department.name,
    location: department.location,
    legalPerson: department.legalPerson,
    locationUrl: department.locationUrl,
    costCenter: department.costCenter,
    VAT: department.VAT,
    id: department.id,
    flexPools: department.flexPools,
    isArchivable: !jobsWithPlacements.length,
    departmentFlexPools: department.flexPoolDepartments.map((fpd) => ({
      id: fpd.flexPoolId,
      name: fpd.flexPool.name,
    })),
  };
};

type DepartmentFormModel = DepartmentDetailModel & {
  branchOfficeId: string;
  billingEntityId: string | null;
};

const departmentResponse2DepartmentFormModel = (
  input: GetDepartmentResponse
): DepartmentFormModel => {
  const model = departmentResponse2DepartmentDetailModel(input);

  return {
    ...model,
    branchOfficeId: input.department.branchOffice.id,
    billingEntityId: input.department.billingEntityId,
    departmentFlexPools: input.department.flexPoolDepartments.map((fpd) => ({
      id: fpd.flexPoolId,
      name: fpd.flexPool.name,
    })),
  };
};

type BranchOfficesSelectModel = { value: string; label: string }[];
const branchOfficeResponse2BranchOfficesSelectModel = (
  branchOfficesResponse: GetBranchOfficesResponse['branchOffices']
): BranchOfficesSelectModel => {
  const branchOffices: BranchOfficesSelectModel = [];
  _.sortBy(branchOfficesResponse, (branchOffice) => branchOffice.name.toLowerCase()).forEach(
    (brnch) => {
      branchOffices.push({
        value: brnch.id,
        label: brnch.name,
      });
    }
  );

  return branchOffices;
};

export {
  departmentsResponse2DepartmentsSelectModel,
  departmentResponse2DepartmentDetailModel,
  departmentResponse2DepartmentFormModel,
  jobCreateDataResponse2JobCreateDataModel,
  shiftPlanningListItemsResponse2PlanningModel,
  OGPUserInfoResponse2OGPUserInfoModel,
  shiftCreateDataResponse2ShiftCreateDataModel,
  userResponse2UserModel,
  userModel2UserPayload,
  userResponse2UserFormModel,
  departmentsResponse2BranchOfficesWithDepartmentsModel,
  branchOfficeResponse2BranchOfficesSelectModel,
};
