import { DateTime } from 'luxon';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';

export const isWithinCancelationPeriod = (shiftData: ShiftDetailData, candidate: ShiftClaimant) => {
  const isCancelationOffer = DateTime.now() > DateTime.fromISO(shiftData.mutableUntilDate);
  return (
    shiftData.isFreelance &&
    isCancelationOffer &&
    candidate.isFlexWorker &&
    candidate.employmentType === 'FREELANCER'
  );
};
